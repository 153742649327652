import { Box, TextareaAutosize } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React, { useState } from "react";
import DropDowns from "./DropDowns";
import { useDispatch, useSelector } from "react-redux";
import { addSimpleQuestions, getQuestions } from "../../../store/Slices/questionsSlice";
import TextFields from "./TextFields";
const bodyStyle = {
  height: "auto",
};
export default function SimpleQuestions({
  simpleQuestions,
  setSimpleQuestions,
  rolesDetails,
  categoriesDetails,
  setAddModalOpen,
  debouncedSearch,
  questionsType,
  page,
}) {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.questions);
  const [closeModal, setCloseModal] = useState();

  const handleQuestionTextChange = (index, event) => {
    const updatedQuestions = [...simpleQuestions];
    updatedQuestions[index].question = event.target.value;
    setSimpleQuestions(updatedQuestions);
  };

  const handleAddQuestion = () => {
    const lastQuestion = simpleQuestions[simpleQuestions.length - 1];
    const newQuestion = {
      question_type_id: lastQuestion?.question_type_id || "",
      role_category_id: lastQuestion?.role_category_id || "",
      role_subcategory_id: lastQuestion?.role_subcategory_id || null,
      roleId: lastQuestion?.roleId || "",
      specific_role: lastQuestion?.specific_role || "",
      location: lastQuestion?.location || "",
      company_name: lastQuestion?.company_name || "",
      question: "",
    };
    setSimpleQuestions([...simpleQuestions, newQuestion]);
  };

  const handleRemoveQuestion = (index) => {
    const updatedQuestions = simpleQuestions.filter((_, i) => i !== index);
    console.log(updatedQuestions, "updatedQuestions");
    setSimpleQuestions(updatedQuestions);
  };

  const handleTextFields = (index, event) => {
    const { name, value } = event.target;
    const updatedQuestions = [...simpleQuestions];
    updatedQuestions[index][name] = value;
    setSimpleQuestions(updatedQuestions);
  };

  const handleLocationChange = (name, index, value) => {
    const updatedQuestions = [...simpleQuestions];
    updatedQuestions[index][name] = value;
    setSimpleQuestions(updatedQuestions);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let payload = {
      questions: simpleQuestions.map((question) => {
        const { roleId, ...remainingQuestion } = question;
        return remainingQuestion;
      }),
    };
    dispatch(
      addSimpleQuestions({
        payload,
        onSuccess: () => {
          if (closeModal) {
            setSimpleQuestions([
              {
                question_type_id: "",
                role_category_id: "",
                role_subcategory_id: null,
                question: "",
                roleId: "",
                specific_role: "",
                location: "",
                company_name: "",
              },
            ]);
          } else {
            // const updatedQuestions = simpleQuestions.map((question) => ({
            //   ...question,
            //   question: "",
            // }));
            // setSimpleQuestions(updatedQuestions);
            const firstQuestion = simpleQuestions[0];
            setSimpleQuestions([
              {
                ...firstQuestion,
                question: "",
              },
            ]);
          }
          dispatch(getQuestions({ page: page, search: debouncedSearch }));
        },
      })
    );
    if (closeModal) {
      setAddModalOpen(false);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Box sx={bodyStyle}>
        {simpleQuestions.map((question, index) => (
          <Box mb={2}>
            <DropDowns
              rolesDetails={rolesDetails}
              question={question}
              categoriesDetails={categoriesDetails}
              Questions={simpleQuestions}
              setQuestions={setSimpleQuestions}
              index={index}
              questionsType={questionsType}
              isDisabled={loading}
            />
            <TextFields
              question={question}
              index={index}
              handleTextFields={handleTextFields}
              handleLocationChange={handleLocationChange}
              isDisabled={loading}
            />
            <TextareaAutosize
              minRows={3}
              placeholder="Enter Question"
              value={question.question}
              onChange={(event) => handleQuestionTextChange(index, event)}
              style={{ width: "100%", marginTop: "10px", padding: "10px" }}
              required
              isDisabled={loading}
            />
            {simpleQuestions.length > 1 && (
              <MDButton
                variant="gradient"
                color="error"
                onClick={() => handleRemoveQuestion(index)}
              >
                Remove
              </MDButton>
            )}
          </Box>
        ))}

        <MDButton variant="gradient" color="info" onClick={handleAddQuestion}>
          Add Another Question
        </MDButton>
      </Box>
      <MDBox mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
        <MDButton
          variant="gradient"
          color="info"
          onClick={() => setAddModalOpen(false)}
          disabled={loading}
        >
          Cancel
        </MDButton>
        <MDButton
          variant="gradient"
          color="info"
          type="submit"
          disabled={loading}
          onClick={() => setCloseModal(true)}
        >
          Submit and Close
        </MDButton>
        <MDButton variant="gradient" color="info" type="submit" disabled={loading}>
          Submit and Continue
        </MDButton>
      </MDBox>
    </form>
  );
}
