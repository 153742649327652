// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { toast } from "react-toastify";
// import { RepositoryFactory } from "repository/RepositoryFactory";
// const roleCategoryRepo = RepositoryFactory.get("roleCategory");

// export const getAllRoleCategories = createAsyncThunk(
//   "roleCategory/getAllRoleCategories",
//   async (_, { rejectWithValue }) => {
//     try {
//       const { data } = await roleCategoryRepo.getAllRoleCategories();
//       if (data.status_code === 200) {
//         return data;
//       }
//     } catch (err) {
//       toast.error(err.response.data.detail);
//       return rejectWithValue(err);
//     }
//   }
// );

// const initialState = {
//   loading: false,
//   allRoleCategories: [],
// };

// const roleCategorySlice = createSlice({
//   name: "roleCategory",
//   initialState,
//   reducers: {},
//   extraReducers: (builder) => {
//     builder
//       //Get all categories
//       .addCase(getAllRoleCategories.pending, (state) => {
//         state.loading = true;
//       })
//       .addCase(getAllRoleCategories.fulfilled, (state, action) => {
//         state.loading = false;
//         state.allRoleCategories = action.payload.data;
//       })
//       .addCase(getAllRoleCategories.rejected, (state, action) => {
//         state.loading = false;
//       });
//   },
// });
// export default roleCategorySlice.reducer;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";
const roleCategoryRepo = RepositoryFactory.get("roleCategory");

export const addRoleCategory = createAsyncThunk(
  "roleCategory/addRoleCategory",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await roleCategoryRepo.addRoleCategory(payload);
      if (data.status_code === 200) {
        onSuccess();
        toast.success(data.detail);
        return data;
      }
    } catch (err) {
      toast.error(err.response.data.detail);
      return rejectWithValue(err);
    }
  }
);
export const getAllRoleCategories = createAsyncThunk(
  "roleCategory/getAllRoleCategories",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await roleCategoryRepo.getAllRoleCategories();
      if (data.status_code === 200) {
        return data;
      }
    } catch (err) {
      toast.error(err.response.data.detail);
      return rejectWithValue(err);
    }
  }
);

export const deleteRoleCategory = createAsyncThunk(
  "roleCategory/deleteRoleCategory",
  async ({ categoryId, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await roleCategoryRepo.deleteRoleCategory(categoryId);
      if (data.status_code === 200) {
        toast.success(data.detail);
        onSuccess();
        return data;
      }
    } catch (err) {
      toast.error(err.response.data.detail);
      return rejectWithValue(err);
    }
  }
);
export const deleteBulkCategory = createAsyncThunk(
  "role/deleteBulkCategory",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const promises = payload.map(async (categoryId) => {
        await roleCategoryRepo.deleteRoleCategory(categoryId);
      });
      await Promise.all(promises);
      onSuccess();
      toast.success("Categories deleted successfully");
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateRoleCategory = createAsyncThunk(
  "roleCategory/updateRoleCategory",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const response = await roleCategoryRepo.updateRoleCategory(payload);
      if (response.status === 200) {
        onSuccess();
        toast.success("Category Updated Successfully");
        return data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getDeveloperRoleSubCategories = createAsyncThunk(
  "role/getDeveloperRoleSubCategories",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await roleCategoryRepo.getDeveloperRoleSubCategories();
      if (data.status_code === 200) {
        return data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  loading: false,
  hasSession: null,
  allRoleCategories: [],
  allSubCategories: [],
};

const roleCategorySlice = createSlice({
  name: "roleCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //Add Role Category
      .addCase(addRoleCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(addRoleCategory.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addRoleCategory.rejected, (state, action) => {
        state.loading = false;
      })
      //Get all categories
      .addCase(getAllRoleCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllRoleCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.allRoleCategories = action.payload.data;
      })
      .addCase(getAllRoleCategories.rejected, (state, action) => {
        state.loading = false;
      })
      //Delete role category
      .addCase(deleteRoleCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteRoleCategory.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteRoleCategory.rejected, (state, action) => {
        state.loading = false;
      })
      //Bulk Delete category
      .addCase(deleteBulkCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteBulkCategory.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteBulkCategory.rejected, (state, action) => {
        state.loading = false;
      })
      //Update role category
      .addCase(updateRoleCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateRoleCategory.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateRoleCategory.rejected, (state, action) => {
        state.loading = false;
      })
      //Get all sub categories
      .addCase(getDeveloperRoleSubCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDeveloperRoleSubCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.allSubCategories = action.payload.data;
      })
      .addCase(getDeveloperRoleSubCategories.rejected, (state) => {
        state.loading = false;
      });
  },
});
export default roleCategorySlice.reducer;
