import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SkillsGapConvertion } from "./SkillsGapConvertion";

const TypingEffect = ({ speed = 50, storeFetchValue }) => {
  const dispatch = useDispatch();
  const [displayedText, setDisplayedText] = useState([]);
  const [handleTyper, setHandleTyper] = useState(true);
  const [index, setIndex] = useState(0);
  const [textArray, setTextArray] = useState([]);

  useEffect(() => {
    if (storeFetchValue) {
      const convertedText = SkillsGapConvertion(storeFetchValue ?? "");
      setTextArray(convertedText);
      setDisplayedText([]);
      setIndex(0);
    }
  }, [storeFetchValue]);

  useEffect(() => {
    if (index < textArray.length) {
      const timeout = setTimeout(() => {
        setDisplayedText((prev) => [...prev, textArray[index]]);
        setIndex((prev) => prev + 1);
      }, speed);

      return () => clearTimeout(timeout);
    }
  }, [index, textArray, speed]);

  useEffect(() => {
    setHandleTyper(storeFetchValue);
  }, [storeFetchValue]);

  return (
    <div className="skillsgap">
      <div
        dangerouslySetInnerHTML={{
          __html: handleTyper ? displayedText?.join("") : "",
        }}
      ></div>
    </div>
  );
};

export default TypingEffect;
