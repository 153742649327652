/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Custom styles for the SidenavCollapse
import {
  collapseIcon,
  collapseIconBox,
  collapseItem,
  collapseText,
} from "examples/Sidenav/styles/sidenavCollapse";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getChat } from "store/Slices/ChatSlice";
import { getUserId } from "store/Slices/ChatSlice";
import { updateConversations } from "store/Slices/ChatSlice";
import { pageRefresh } from "config/socketConfig";
import { socket } from "config/socketConfig";
import { Badge, Box } from "@mui/material";

function SidenavCollapse({ icon, name, textColor, active, ...rest }) {
  const dispatch = useDispatch();
  const { orgTheme } = useSelector((state) => state.oraganizationTheme);
  const { connectedChats, userId } = useSelector((state) => state.chats);
  const { user } = useSelector((state) => state.auth);
  const [controller] = useMaterialUIController();
  const [textActiveColor, setTextActiveColor] = useState(null);
  const [totalUnreadChats, setTotalUnreadChats] = useState(0);
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  useEffect(() => {
    if (
      orgTheme?.sidebar_color === "LavenderMist" ||
      orgTheme?.sidebar_color === "MintGreen" ||
      orgTheme?.sidebar_color === "NeonLime" ||
      orgTheme?.sidebar_color === "PeachFuzz"
    ) {
      setTextActiveColor("#000");
    } else {
      setTextActiveColor("#fff");
    }
  }, [orgTheme]);
  useEffect(() => {
    if (connectedChats && userId) {
      let totalUnreadCount = 0;
      connectedChats.forEach((chat) => {
        chat.unread_messages?.forEach((unread) => {
          if (unread.user_id === userId.user_id) {
            totalUnreadCount += unread.unread_messages; // Sum unread messages
          }
        });
      });
      setTotalUnreadChats(totalUnreadCount);
    }
  }, [connectedChats, userId]);
  return (
    <ListItem component="li">
      <MDBox
        {...rest}
        sx={(theme) =>
          collapseItem(theme, {
            active,
            transparentSidenav,
            whiteSidenav,
            darkMode,
            sidenavColor,
          })
        }
      >
        <ListItemIcon
          sx={
            ((theme) =>
              collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active }),
            {
              color: textColor === "white" ? "#fff" : active ? textActiveColor : textColor,
              minWidth: "36px",
            })
          }
        >
          {typeof icon === "string" ? (
            <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
          ) : (
            icon
          )}
        </ListItemIcon>

        <ListItemText
          primary={name}
          primaryTypographyProps={{
            sx: {
              fontSize: "14px", // sets the font size for the primary text
            },
          }}
          sx={
            ((theme) =>
              collapseText(theme, {
                miniSidenav,
                transparentSidenav,
                whiteSidenav,
                active,
              }),
            {
              color: textColor === "white" ? "#fff" : active ? textActiveColor : textColor,
              fontSize: "14px",
              // I want to set font size of inside span item
            })
          }
        />
        {name === "Chats" && totalUnreadChats > 0 && (
          <Badge
            badgeContent={totalUnreadChats > 99 ? "+99" : totalUnreadChats}
            color="primary"
            overlap="circular"
            sx={{
              "& .MuiBadge-badge": {
                backgroundColor: "#1e40af", // Equivalent to your bg-blue-600
                color: "white",
                width: 20, // Adjust for the w-7
                height: 20, // Adjust for the h-7
                borderRadius: "50%", // Rounded full
                fontSize: "10px", // Equivalent to text-sm
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "5px",
              },
            }}
          >
            {/* Placeholder for the chat icon or other content */}
            <Box component="span" sx={{ width: 0 }} />
          </Badge>
        )}
      </MDBox>
    </ListItem>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default SidenavCollapse;
