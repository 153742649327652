import axios from "axios";
import { toast } from "react-toastify";
const baseDomain = process.env.REACT_APP_API_URL;
const baseURL = `${baseDomain}/`;
let axiosObj;
axiosObj = axios.create({
  baseURL,
  headers: {
    "Cache-Control": "no-cache",
  },
});

axiosObj.interceptors.request.use((config) => {
  const serializedUid = localStorage.getItem("auth");
  if (serializedUid !== null) {
    config.headers.Authorization = `Bearer ${serializedUid}`;
  }
  return config;
});

axiosObj.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (
      (error.response.status === 401 || error.response.status === 403) &&
      error.response?.data?.detail !== "User is inactive or suspended"
    ) {
      try {
        // Call API to refresh the token
        const serializedRefreshToken = localStorage.getItem("refreshAuth");
        if (serializedRefreshToken !== null) {
          let axiosObj;
          axiosObj = axios.create({
            baseURL,
            headers: {
              "Cache-Control": "no-cache",
              Authorization: `Bearer ${serializedRefreshToken}`,
            },
          });
          const { data } = await axiosObj.post("/es/refresh");
          localStorage.setItem("auth", data.accessToken);
          localStorage.setItem("refreshAuth", data.refreshToken);

          originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;

          // Retry the original request with the new token
          return axiosObj(originalRequest);
        } else {
          toast.error("Your session expired. Please log in again.");
          setTimeout(() => {
            localStorage.clear();
            window.location.href = "/authentication/sign-in";
          }, 1500);
        }
      } catch (refreshError) {
        toast.error("Your session expired. Please log in again.");
        setTimeout(() => {
          localStorage.clear();
          window.location.href = "/authentication/sign-in";
        }, 1500);
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosObj;
