import Repository from "./Repository";
const GET_USER_ID = "/chats/user/get-id";
const UNREAD_CHAT = "/chats/mark/read";
const ChatRepository = {
  startChat(payload) {
    return Repository.post(`/chats/`, payload);
  },
  sendMessage(payload) {
    return Repository.post(`/chats/messages`, payload);
  },
  getAllChats() {
    return Repository.get(`/chats/user/chats`);
  },
  getSingleChat(id) {
    return Repository.get(`/chats/${id}`);
  },
  getUserId() {
    return Repository.get(GET_USER_ID);
  },
  unreadChat(chatId) {
    return Repository.get(`${UNREAD_CHAT}?chat_id=${chatId}`);
  },
};

export default ChatRepository;
