import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getInvoices } from "store/Slices/subscriptionSlice";
import Loader from "components/Loader";
import moment from "moment";
import { toast } from "react-toastify";

const Billings = ({ storePermissions }) => {
  const dispatch = useDispatch();
  const [loader, setloader] = useState(false);
  const { invoices, loading } = useSelector((state) => state.subScription);
  const [rows, setRow] = useState([]);

  const handleDisabled = (val) => {
    if (storePermissions) {
      if (storePermissions.hasOwnProperty(val)) {
        return !storePermissions[val];
      }
      return null;
    }
  };

  const downloadPdf = (pdfUrl) => {
    setloader(true);
    if (pdfUrl) {
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "invoice.pdf";
      link.click();
      setloader(false);
    } else {
      console.error("PDF URL is missing.");
      setloader(false);
    }
  };

  const columns = [
    { Header: "Period Start", accessor: "period_start", align: "center" },
    { Header: "Period End", accessor: "period_end", align: "center" },
    { Header: "Invoice Date", accessor: "invoice_date", align: "left" },
    { Header: "Amount", accessor: "amount", align: "left" },
    { Header: "Status", accessor: "status", align: "left" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  // console.log(rows, "customAddUser");

  useEffect(() => {
    if (invoices && invoices.length >= 1) {
      const customAddUsers = invoices?.map((data, index) => {
        return {
          period_start: (
            <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
              {moment.unix(data?.period_start).format("DD MMM YYYY")}
            </MDTypography>
          ),
          period_end: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {data?.period_end}
            </MDTypography>
          ),
          invoice_date: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {data?.invoice_created}
            </MDTypography>
          ),
          amount: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {data?.total} {data?.currency}
            </MDTypography>
          ),
          status: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {data?.status}
            </MDTypography>
          ),
          action: (
            <MDBox sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <>
                <Button
                  variant="contained"
                  size="small"
                  disabled={handleDisabled("update") || loader}
                  onClick={() => downloadPdf(data?.invoice_pdf)}
                  sx={{
                    ml: 1.3,
                    color: "#fff",
                    bgcolor: "info",
                    "&:hover": {
                      bgcolor: "darkslategray",
                    },
                  }}
                >
                  Download Invoice
                </Button>
              </>
            </MDBox>
          ),
        };
      });
      setRow(customAddUsers);
    }
  }, [invoices]);

  useEffect(() => {
    dispatch(getInvoices({ onSuccess: () => {} }))
      .then((res) => {})
      .catch((rejected) => toast.error(rejected?.response?.data?.detail));
  }, []);

  return (
    <Card
      sx={{
        position: "relative",
        mt: 4,
        mx: 1,
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;",
      }}
    >
      {loader || (loading && <Loader />)}
      <MDBox
        mx={2}
        mt={3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          Billing History
        </MDTypography>
      </MDBox>
      <MDBox pt={3}>
        <Card id="delete-account">
          <MDBox pt={1} pb={2} px={2}>
            <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
              <DataTable
                table={{ columns, rows }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
                checkBoxes={false}
              />
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </Card>
  );
};

export default Billings;
