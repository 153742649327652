import { Icon, Pagination } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Loader from "components/Loader";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { addSubOrg } from "components/schema";
import { getRouteByNumber } from "context";
import { featurePermissionsObject } from "context";
import { permission } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getAllSubOrganizations,
  postInviteSubOrg,
  resendInvitation,
  revokeInvitaion,
  activateInvitation,
  deleteInvitation,
} from "store/Slices/suborganizationSlice";

const SubOrganizations = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, subOrganizations, paginationData } = useSelector(
    (state) => state.subOrganization
  );
  const { user } = useSelector((state) => state.auth);
  const [storePermissions, setStorePermissions] = useState(null);
  const [open, setOpen] = useState(false);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const parsedObject = JSON.parse(user?.rolePermissions);
  let pagePermissions;
  let featurePermissions;
  if (parsedObject) {
    const [key, value] = Object.entries(parsedObject)[0];
    pagePermissions = key;
    featurePermissions = value;
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: { email: "", sub_organization_name: "" },
    validationSchema: addSubOrg,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let payload = {
        email: values.email,
        name: values.sub_organization_name,
      };
      let pagination = { page, size };
      if (subOrganizations.length === size) {
        pagination.page = page + 1;
        setPage(page + 1);
      }
      console.log(payload, pagination, "payload");
      dispatch(postInviteSubOrg({ payload, pagination }))
        .unwrap()
        .then(() => {
          handleClose();
          resetForm();
        })
        .catch(() => {});
    },
  });

  const handleDisabled = (val) => {
    if (storePermissions) {
      if (storePermissions.hasOwnProperty(val)) {
        return !storePermissions[val];
      }
      return null;
    }
  };

  // Modal open function
  const handleOpen = () => {
    setOpen(true);
  };

  //Modal close function
  const handleClose = () => {
    resetForm();
    setOpen(false);
  };

  const handleRevoke = (payload) => {
    dispatch(revokeInvitaion({ payload, pagination: { page, size } }));
  };

  const handleActiveRevoke = (payload) => {
    dispatch(activateInvitation({ payload, pagination: { page, size } }));
  };
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleResendInvitation = (email) => {
    dispatch(resendInvitation(email));
  };

  const handleDelete = (payload) => {
    let pagination = { page, size };
    if (subOrganizations.length === 1 && page > 1) {
      pagination.page = page - 1;
      setPage(page - 1);
    }
    dispatch(deleteInvitation({ payload, pagination }));
  };
  ///for store data on form
  useEffect(() => {
    setColumns([
      { Header: "Sub Organization Name", accessor: "sub_organization_name", align: "left" },
      { Header: "Created At", accessor: "createdAt", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Updated On", accessor: "updatedon", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ]);
    // add user array
    const customAddAdmin =
      subOrganizations &&
      subOrganizations.length >= 1 &&
      subOrganizations.map((data, index) => {
        return {
          sub_organization_name: (
            <MDTypography
              component="a"
              href="#"
              variant="button"
              color="text"
              fontWeight="medium"
              key={index + 100}
            >
              {data?.name}
            </MDTypography>
          ),
          createdAt: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {moment(data?.created_on).format("DD MMM YYYY")}
            </MDTypography>
          ),
          status: (
            <Typography
              component="a"
              href="#"
              variant="caption"
              color="textPrimary"
              fontWeight="medium"
            >
              <Box
                component="span"
                sx={{
                  ...pillSx,
                  backgroundColor:
                    data?.status === "joined"
                      ? "#388e3c"
                      : data?.status === "revoked"
                      ? "#ef5350"
                      : data?.status === "pending"
                      ? "#f57c00"
                      : "#ab47bc",
                  color: "#fff",
                }}
              >
                {data?.status}
              </Box>
            </Typography>
          ),
          updatedon: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {moment(data?.updated_on).format("DD MMM YYYY")}
            </MDTypography>
          ),
          action: (
            <MDBox sx={{ display: "flex", justifyContent: "start", alignItems: "start" }}>
              <Button
                variant="contained"
                size="small"
                disabled={!data?.organization_id}
                onClick={() => navigate(`/viewsuborganization/${data?.organization_id}`)}
                sx={{
                  ml: 1.3,
                  color: "#fff",
                  bgcolor: "#ef5350",
                  "&:hover": {
                    bgcolor: "darkslategray",
                  },
                }}
              >
                <Icon color="#fff" sx={{ fontSize: "20px" }}>
                  visibility
                </Icon>
              </Button>
              {data?.status !== "revoked" && (
                <Button
                  variant="contained"
                  size="small"
                  disabled={isLoading || handleDisabled("delete")}
                  onClick={() =>
                    handleRevoke({ email: data?.email, sub_organization_id: data?.id })
                  }
                  sx={{
                    ml: 1.3,
                    color: "#fff",
                    bgcolor: "#ef5350",
                    "&:hover": {
                      bgcolor: "darkslategray",
                    },
                  }}
                >
                  Revoke
                </Button>
              )}
              {data?.status == "revoked" ? (
                <>
                  <Button
                    variant="contained"
                    size="small"
                    disabled={isLoading || handleDisabled("update")}
                    onClick={() =>
                      handleActiveRevoke({ email: data?.email, sub_organization_id: data?.id })
                    }
                    sx={{
                      ml: 1.3,
                      color: "#fff",
                      bgcolor: "#f57c00",
                      "&:hover": {
                        bgcolor: "darkslategray",
                      },
                    }}
                  >
                    Active Revoked
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    disabled={isLoading || handleDisabled("delete")}
                    onClick={() => {
                      handleDelete({ email: data?.email });
                    }}
                    sx={{
                      ml: 1.3,
                      color: "#fff",
                      bgcolor: "#ef5350",
                      "&:hover": {
                        bgcolor: "darkslategray",
                      },
                    }}
                  >
                    Delete
                  </Button>
                </>
              ) : data?.status == "pending" ? (
                <>
                  <Button
                    variant="contained"
                    size="small"
                    disabled={isLoading || handleDisabled("update")}
                    onClick={() => handleResendInvitation(data?.email)}
                    sx={{
                      ml: 1.3,
                      color: "#fff",
                      bgcolor: "info",
                      "&:hover": {
                        bgcolor: "darkslategray",
                      },
                    }}
                  >
                    Resend Invitation
                  </Button>
                </>
              ) : (
                <></>
              )}
            </MDBox>
          ),
        };
      });
    setRows(Array.isArray(customAddAdmin) ? customAddAdmin : []);
  }, [subOrganizations]);

  useEffect(() => {
    if (pagePermissions !== permission.admin && pagePermissions !== permission.subOrganization) {
      const routeLink = getRouteByNumber(pagePermissions);
      return navigate(routeLink);
    }
    if (featurePermissions) {
      const data = featurePermissionsObject(featurePermissions);
      setStorePermissions(data);
    }
  }, []);

  ////styling for modal box
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    boxShadow: 24,
    p: 4,
  };

  const pillSx = {
    display: "inline-block",
    padding: "0.2em 0.6em",
    borderRadius: "9999px",
    fontSize: "0.75rem",
    fontWeight: 500,
    textAlign: "center",
    color: "white",
  };

  useEffect(() => {
    dispatch(getAllSubOrganizations({ size, page }));
  }, [size, page]);

  useEffect(() => {
    if (user?.data?.is_sub_organization) {
      toast.error("You are already a sub organization");
      return navigate(-1);
    }
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Sub Organizations
                </MDTypography>
                <Box>
                  <Button
                    variant="contained"
                    sx={{
                      color: "#fff",
                      bgcolor: "#000",
                      "&:hover": {
                        bgcolor: "darkslategray",
                      },
                    }}
                    onClick={handleOpen}
                    disabled={isLoading || handleDisabled("create")}
                  >
                    Add New
                  </Button>
                </Box>
              </MDBox>
              <MDBox pt={3}>
                {subOrganizations && subOrganizations.length >= 1 ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                    checkBoxes={false}
                  />
                ) : (
                  <MDTypography
                    component="h2"
                    href="#"
                    color="text"
                    fontWeight="bold"
                    sx={{
                      textAlign: "center",
                      my: 10,
                    }}
                  >
                    YOU DON'T HAVE ANY SUB ORGANIZATION
                  </MDTypography>
                )}
              </MDBox>
              <MDBox
                m={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  count={paginationData?.total_pages}
                  page={page}
                  onChange={handlePageChange}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* user Page ENd */}

      {/* Add modal box */}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style}>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1} mb={1}>
              Add New Sub Organization
            </MDTypography>
          </MDBox>
          <MDBox mx={1} p={1} mt={1}>
            <form onSubmit={handleSubmit}>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Sub Organization Name"
                  name="sub_organization_name"
                  value={values.sub_organization_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                />
                {errors.sub_organization_name && touched.sub_organization_name ? (
                  <MDTypography color="error" sx={{ fontSize: "13px" }}>
                    {<p>{errors.sub_organization_name}</p>}
                  </MDTypography>
                ) : null}
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                />
                {errors.email && touched.email ? (
                  <MDTypography color="error" sx={{ fontSize: "13px" }}>
                    {<p>{errors.email}</p>}
                  </MDTypography>
                ) : null}
              </MDBox>

              <MDBox mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
                <MDButton variant="gradient" color="info" onClick={handleClose}>
                  Cancel
                </MDButton>
                <MDButton variant="gradient" color="info" type="submit">
                  Send
                </MDButton>
              </MDBox>
            </form>
          </MDBox>
        </Card>
      </Modal>
      {/* End modal box */}
    </DashboardLayout>
  );
};

export default SubOrganizations;
