import { useEffect, useRef } from "react";

const useScrollToBottom = (dependencies = []) => {
  const elementRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, dependencies);

  const scrollToBottom = () => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return elementRef;
};

export default useScrollToBottom;
