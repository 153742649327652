import Loader from "components/Loader";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import UserDetailHeader from "./component/UserDetailHeader";
import UserStats from "./component/UserStats";
import { useEffect, useState } from "react";
import UserResume from "./component/UserResume";
import ProfileInformation from "./component/ProfileInformation";
import UserPRounds from "./component/UserPRounds";
import UserSimulations from "./component/UserSimulations";
import UserOctagon from "./component/UserOcatgon";
import { useSelector } from "react-redux";
import { permission } from "context";
import { getRouteByNumber } from "context";
import { featurePermissionsObject } from "context";

const UserAnalytics = () => {
  const { user } = useSelector((state) => state.auth);
  const [parentActiveState, setParentActiveState] = useState(0);
  const [storePermissions, setStorePermissions] = useState(null);
  const [tempRef, setTempRef] = useState(null);
  const parsedObject = JSON.parse(user?.rolePermissions);
  let pagePermissions;
  let featurePermissions;
  if (parsedObject) {
    const [key, value] = Object.entries(parsedObject)[0];
    pagePermissions = key;
    featurePermissions = value;
  }
  useEffect(() => {
    if (pagePermissions !== permission.admin && pagePermissions !== permission.users) {
      const routeLink = getRouteByNumber(pagePermissions);
      return navigate(routeLink);
    }
    if (featurePermissions) {
      const data = featurePermissionsObject(featurePermissions);
      setStorePermissions(data);
      console.log(data, "featurePermissionsfeaturePermissions");
    }
  }, []);
  return (
    <DashboardLayout>
      {false && <Loader />}
      <UserDetailHeader setParentActiveState={setParentActiveState}>
        {parentActiveState == 0 && <ProfileInformation storePermissions={storePermissions} />}
        {parentActiveState == 1 && <UserStats />}
        {parentActiveState == 2 && <UserPRounds storePermissions={storePermissions} />}
        {parentActiveState == 3 && <UserSimulations storePermissions={storePermissions} />}
        {parentActiveState == 4 && <UserOctagon storePermissions={storePermissions} />}
        {parentActiveState == 5 && (
          <UserResume tempRef={tempRef} storePermissions={storePermissions} />
        )}
      </UserDetailHeader>
    </DashboardLayout>
  );
};

export default UserAnalytics;
