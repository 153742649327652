import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { convertTimestampToReadableDate, MonthlydummySubscriptionData } from "./DummyData";
// Data
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Loader";
import { getSubscription } from "store/Slices/authSlice";
import { toast } from "react-toastify";
import PricingCards from "./PricingCards";
import { useNavigate } from "react-router-dom";
import CancelSubscriptionModal from "examples/Modal/CancelSubscriptionModal";
import { getUsers } from "store/Slices/usersSlice";
import { getRouteByNumber } from "context";
import { permission } from "context";
import { featurePermissionsObject } from "context";
import { getStats } from "store/Slices/usersSlice";
import { handleRevokeSubscriptionCancel } from "store/Slices/subscriptionSlice";
import { getPaymentCard } from "store/Slices/profileSlice";

const Subscriptions = () => {
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.subScription);
  const { users } = useSelector((state) => state.users);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [userStats, setUserStats] = useState(null);
  console.log(userStats, "userStats");
  const [cancelSubModal, setCancelSubModal] = useState(false);
  const [pricingId, setPricingId] = useState(null);
  const [isCheckYearly, setIsCheckYearly] = useState(false);
  const [storePermissions, setStorePermissions] = useState(null);
  const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);

  const parsedObject = JSON.parse(user?.rolePermissions);
  let pagePermissions;
  let featurePermissions;
  if (parsedObject) {
    const [key, value] = Object.entries(parsedObject)[0];
    pagePermissions = key;
    featurePermissions = value;
  }

  const activeBundleData = MonthlydummySubscriptionData.filter(
    (data) => data?.id === user?.activeBundle
  );

  const handleClose = () => {
    setCancelSubModal(false);
  };

  const getCurrentSubscription = () => {
    dispatch(getSubscription())
      .unwrap()
      .then((res) => {})
      .catch((rejected) => {
        toast.error(rejected?.response?.data?.detail);
      });
  };

  const handleDisabled = (val) => {
    if (storePermissions) {
      if (storePermissions.hasOwnProperty(val)) {
        return !storePermissions[val];
      }
      return null;
    }
  };

  const handleRevokeSubscription = () => {
    dispatch(
      handleRevokeSubscriptionCancel({
        onSuccess: () => {
          dispatch(getSubscription());
        },
      })
    );
  };

  useEffect(() => {
    getCurrentSubscription();
    let payload = `page=${1}&search=${""}`;

    dispatch(
      getUsers({
        payload,
        onSuccess: () => {},
      })
    );
    if (pagePermissions !== permission.admin && pagePermissions !== permission.subscription) {
      const routeLink = getRouteByNumber(pagePermissions);
      return navigate(routeLink);
    }
    if (featurePermissions) {
      const data = featurePermissionsObject(featurePermissions);
      setStorePermissions(data);
      console.log(data, "featurePermissionsfeaturePermissions");
    }
    dispatch(
      getStats({
        onSuccess: (res) => {
          setUserStats(res);
        },
      })
    )
      .unwrap()
      .then((res) => {})
      .catch((rejected) => toast.error(rejected?.response?.data?.detail));

    if (!user?.data) {
      dispatch(getPaymentCard())
        .unwrap()
        .then((res) => {})
        .catch((rejected) => toast.error(rejected?.response?.data?.detail));
    }
  }, []);

  return (
    <DashboardLayout>
      {loading && <Loader />}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Manage Subscription Plan
                </MDTypography>
              </MDBox>
              {user?.data?.is_sub_organization && (
                <MDBox pt={1} px={3} display="flex" alignItems="start" justifyContent="center">
                  <Grid
                    container
                    spacing={2}
                    mt={2}
                    mb={4}
                    px={10}
                    sx={{ display: "flex", alignItems: "stretch", justifyContent: "center" }}
                  >
                    <Typography
                      variant="h1"
                      sx={{ textAlign: "center", fontWeight: "bold", color: "#000", my: 2 }}
                    >
                      Supercharge Your Career Center And Boost Job Placement Permanently
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{ textAlign: "center", color: "#445164", mb: 2, lineHeight: 1.8 }}
                    >
                      We guarantee a 25% increase in job placements within 3 months, or we’ll let
                      you use the platform for free until you do
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{ textAlign: "center", color: "#445164", mb: 2, lineHeight: 1.8 }}
                    >
                      You have been subscribed to organisation plan under organisation{" "}
                      <Box component="span" sx={{ textDecoration: "underline" }}>
                        {user?.data?.parent_organization_name}
                      </Box>
                    </Typography>
                  </Grid>
                </MDBox>
              )}

              {!user?.data?.is_sub_organization && (
                <>
                  <MDBox mt={4} display="flex" justifyContent="center" alignItems="center">
                    <button
                      className={`ic-btn-go-to-pro ic-btn-primary ${
                        handleDisabled("update") || user?.cancelation ? "" : "button_cursor"
                      }`}
                      style={{ width: "200px", fontSize: "15px" }}
                      disabled={handleDisabled("update") || user?.cancelation}
                      onClick={() => setIsCustomModalOpen(true)}
                    >
                      Select Custom Plan
                    </button>
                  </MDBox>
                  <MDBox pt={1} px={3} display="flex" alignItems="start" justifyContent="center">
                    <Grid
                      container
                      spacing={2}
                      mt={2}
                      mb={4}
                      sx={{ display: "flex", alignItems: "stretch", justifyContent: "center" }}
                    >
                      {!isCheckYearly &&
                        MonthlydummySubscriptionData.map((data, index) => (
                          <Grid item xs={12} md={6} lg={4} sx={{ display: "flex" }}>
                            <PricingCards
                              plan={data}
                              // handleOpen={handleOpen}
                              pricingId={pricingId}
                              isCheckYearly={isCheckYearly}
                              handleDisabled={handleDisabled}
                              userStats={userStats}
                              isCustomModalOpen={isCustomModalOpen}
                              setIsCustomModalOpen={setIsCustomModalOpen}
                            />
                          </Grid>
                        ))}
                    </Grid>
                  </MDBox>
                  {MonthlydummySubscriptionData[0].id != user?.activeBundle && (
                    <>
                      {user?.cancelation ? (
                        <MDBox
                          my={2}
                          px={3}
                          display="flex"
                          justifyContent="center"
                          flexDirection="column"
                          alignItems="center"
                        >
                          <MDTypography
                            variant="h6"
                            fontWeight="medium"
                            my={2}
                            sx={{ textAlign: "center", color: "red" }}
                          >
                            Your subscription will be cancelled on{" "}
                            {convertTimestampToReadableDate(user?.cancelation_time)}
                          </MDTypography>
                          <button
                            className="ic-btn-go-to-pro ic-btn-primary button_cursor"
                            style={{ width: "200px", fontSize: "15px" }}
                            onClick={handleRevokeSubscription}
                            disabled={loading}
                          >
                            Revoke Subscription
                          </button>
                        </MDBox>
                      ) : (
                        <MDBox my={2} px={3} display="flex" justifyContent="center">
                          <button
                            className="ic-btn-go-to-pro ic-btn-danger button_cursor"
                            onClick={() => {
                              if (
                                user?.activeBundle == null ||
                                user?.activeBundle == "QolEraPnrw"
                              ) {
                                toast.error("Cannot Cancel the Trail Version");
                              } else {
                                setCancelSubModal(true);
                              }
                            }}
                          >
                            Cancel Subscription
                          </button>
                        </MDBox>
                      )}
                    </>
                  )}
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <CancelSubscriptionModal modal={cancelSubModal} handleClose={handleClose} />
    </DashboardLayout>
  );
};

export default Subscriptions;
