export function SkillsGapConvertion(text) {
  if (!text) return [];

  // Replace headings
  text = text.replace(/^### (.*$)/gim, "<h3>$1</h3>");
  text = text.replace(/^#### (.*$)/gim, "<h4>$1</h4>");

  // Replace bold text
  text = text.replace(/\*\*(.*?)\*\*/gim, "<strong>$1</strong>");

  // Replace lists
  text = text.replace(/^\s*-\s(.*$)/gim, "<li>$1</li>");

  // Wrap lists with <ul> tags
  text = text.replace(/(<li>.*<\/li>)/gim, "<ul>$1</ul>");

  // Replace new lines with <p> tags
  text = text.replace(/\n\n/gim, "</p><p>");
  text = text.replace(/\n/gim, "<br>");

  // Wrap the entire content in <p> tags
  text = `<p>${text}</p>`;

  // Remove <br> inside lists and headings
  text = text.replace(/(<h[34]>.*)<br>(.*<\/h[34]>)/gim, "$1 $2");
  text = text.replace(/(<li>.*)<br>(.*<\/li>)/gim, "$1 $2");

  // Remove duplicate <ul> tags
  text = text.replace(/<\/ul><ul>/gim, "");

  // Split by chunks of HTML tags or words
  return text.split(/(<[^>]+>|[^<>\s]+|\s+)/g).filter(Boolean);
}
