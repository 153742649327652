import authRepository from "./AuthRepository";
import DashboardRepository from "./DashboardRepository";
import OnBoardingRepository from "./OnBoardingRepository";
import ReportAnalyticsRepository from "./ReportAnalyticsRepository";
import themeRepository from "./ThemeRepository";
import questionsRepository from "./QuestionsRepository";
import roleRepository from "./RoleRepository";
import roleCategoryRepository from "./RoleCategoryRepository";
import adminRepository from "./AdminRepository";
import ChatRepository from "./ChatRepository";
import TaskRepository from "./TaskRepository";
import subOrgRepository from "./SubOrgRepository";
const repositories = {
  auth: authRepository,
  Dashboard: DashboardRepository,
  Theme: themeRepository,
  boarding: OnBoardingRepository,
  ReportAnalytics: ReportAnalyticsRepository,
  questions: questionsRepository,
  role: roleRepository,
  roleCategory: roleCategoryRepository,
  chat: ChatRepository,
  admin: adminRepository,
  task: TaskRepository,
  subOrg: subOrgRepository,
};

export const RepositoryFactory = {
  get: (name) => repositories[name],
};
