import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import { Button, Icon, Modal, Tooltip } from "@mui/material";
import MDButton from "components/MDButton";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import Loader from "components/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  getPaymentCard,
  updatePaymentCard,
  deleteUsersCard,
  addPaymentCard,
} from "store/Slices/profileSlice";
import { toast } from "react-toastify";
import { cardBrands } from "./data/CardsData";

const stripeKeys =
  process.env.REACT_APP_ENVIORMENT === "test"
    ? "pk_test_51Ot01YC6xSXKGdgmo8VM7GBORwlXrRHqsX8DiRmpNpAd4ufhzHF79L4RtvZUOw5nSHcTipLiLr6WMDk1yhrt05bi00Pq3wPwfq"
    : "pk_live_51Ot01YC6xSXKGdgmVwoZGN4gdVaGjaWkzf5hQ3stTphEDI2rHG5EzjdLOajBI96xHALSHtbHQ1Yx4PyiYzra1skK00Go2drHWm";

const Payments = ({ storePermissions }) => {
  const [stripePromise, setStripePromise] = useState(null);
  const darkMode = false;
  const dispatch = useDispatch();
  const { payments, loading } = useSelector((state) => state.orgProfile);
  const [open, setOpen] = useState(false);

  const handleDisabled = (val) => {
    if (storePermissions) {
      if (storePermissions.hasOwnProperty(val)) {
        return !storePermissions[val];
      }
      return null;
    }
  };

  useEffect(() => {
    const loadStripeScript = async () => {
      const stripe = await loadStripe(stripeKeys);
      setStripePromise(stripe);
    };
    loadStripeScript();
    getCards();
  }, []);

  const getCards = () => {
    dispatch(getPaymentCard())
      .unwrap()
      .then((res) => {})
      .catch((rejected) => toast.error(rejected?.response?.data?.detail));
  };

  const updateCard = (payload) => {
    dispatch(
      updatePaymentCard({
        payload,
        onSuccess: () => {
          getCards();
        },
      })
    )
      .unwrap()
      .then((res) => {})
      .catch((rejected) => toast.error(rejected?.response?.data?.detail));
  };

  const deleteCard = (id) => {
    dispatch(deleteUsersCard(id))
      .unwrap()
      .then((res) => {
        toast.success("Card Deleted Successfully");
        getCards();
      })
      .catch((rejected) => toast.error(rejected?.response?.data?.detail));
  };

  const handleSubmit = async (e, elements, stripe) => {
    // console.log("submitttttt", elements, stripe);
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const { token, error } = await stripe.createToken(elements.getElement(CardNumberElement));
    if (error) {
      return toast.error(error.message);
    }

    if (token) {
      const tokenID = token?.id;
      dispatch(addPaymentCard(tokenID))
        .unwrap()
        .then((res) => {
          toast.success(res.detail);
          handleClose();
          getCards();
        })
        .catch((rejected) => toast.error(rejected?.response?.data?.detail));
    }
  };

  // Modal open function
  const handleOpen = () => {
    setOpen(true);
  };

  //Modal close function
  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    boxShadow: 24,
    p: 4,
  };
  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Card
        sx={{
          position: "relative",
          mt: 4,
          mx: 1,
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;",
        }}
      >
        <MDBox
          mx={2}
          mt={4}
          py={3}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <MDTypography variant="h6" color="white">
            PAYMENTS
          </MDTypography>
          <MDButton
            variant="gradient"
            color="dark"
            onClick={handleOpen}
            disabled={handleDisabled("create")}
          >
            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
            &nbsp;add new card
          </MDButton>
        </MDBox>
        <MDBox pt={3}>
          <Card id="delete-account">
            <MDBox p={2}>
              <Grid container spacing={3}>
                {payments && payments?.length > 0 ? (
                  payments?.map((item, index) => (
                    <Grid key={index} item xs={12} md={12}>
                      <MDBox
                        borderRadius="lg"
                        display="flex"
                        alignItems="center"
                        p={3}
                        sx={{
                          flexDirection: { xs: "column", sm: "row" },
                          justifyContent: { xs: "center", sm: "space-between" },
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <MDBox
                          component="img"
                          // src={`/images/logos/${item?.brand}.png`}
                          src={cardBrands.find((brand) => brand?.brand === item?.brand)?.img}
                          alt={item?.brand}
                          width="10%"
                          // mr={2}
                          sx={{
                            marginRight: { xs: "0", sm: "20px" },
                            marginBottom: { xs: "20px", sm: "0" },
                          }}
                        />
                        <MDTypography
                          variant="h6"
                          fontWeight="medium"
                          sx={{
                            marginBottom: { xs: "20px", sm: "0" },
                          }}
                        >
                          ****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;{item?.last4}
                        </MDTypography>
                        <MDBox
                          // ml="auto"
                          lineHeight={0}
                          color={darkMode ? "white" : "dark"}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: { xs: "0", sm: "auto" },
                          }}
                        >
                          {item?.isDefault ? (
                            <MDBadge
                              badgeContent="Default"
                              color="success"
                              variant="gradient"
                              size="md"
                              sx={{
                                marginRight: "10px",
                              }}
                            />
                          ) : (
                            <MDButton
                              variant="contained"
                              sx={{
                                marginRight: "10px",
                              }}
                              disabled={handleDisabled("update")}
                              onClick={() => updateCard(item?.id)}
                            >
                              Make it Default
                            </MDButton>
                          )}
                          {payments?.length > 1 && (
                            <Tooltip title="Delete Card" placement="top">
                              <Button
                                disabled={handleDisabled("delete")}
                                onClick={() => deleteCard(item?.id)}
                              >
                                <Icon sx={{ cursor: "pointer" }} fontSize="small" color="error">
                                  delete
                                </Icon>
                              </Button>
                            </Tooltip>
                          )}
                        </MDBox>
                      </MDBox>
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12} md={12}>
                    <MDBox
                      borderRadius="lg"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      p={3}
                    >
                      <MDTypography variant="h6" fontWeight="medium">
                        No Cards To show
                      </MDTypography>
                    </MDBox>
                  </Grid>
                )}
              </Grid>
            </MDBox>
          </Card>
        </MDBox>
      </Card>

      {/* Modal box */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style}>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1} mb={1}>
              Add New Card
            </MDTypography>
          </MDBox>
          <MDBox mx={1} p={1} mt={1}>
            {stripePromise && (
              <Elements stripe={stripePromise}>
                <ElementsConsumer>
                  {({ elements, stripe }) => (
                    <form onSubmit={(e) => handleSubmit(e, elements, stripe)}>
                      <MDBox mb={2}>
                        <label style={{ fontSize: "12px" }}>Card Number</label>
                        <div
                          style={{
                            border: "1px solid #d1d5db",
                            borderRadius: "5px",
                            padding: "10px",
                          }}
                        >
                          <CardNumberElement
                            required
                            options={{
                              placeholder: "XXXX XXXX XXXX XXXX",
                              style: {
                                base: {
                                  fontSize: "16px",
                                },
                                invalid: {
                                  color: "#9e2146",
                                },
                              },
                            }}
                          />
                        </div>
                      </MDBox>
                      <MDBox mb={2}>
                        <label style={{ fontSize: "12px" }}>Expiry Date</label>
                        <div
                          style={{
                            border: "1px solid #d1d5db",
                            borderRadius: "5px",
                            padding: "10px",
                          }}
                        >
                          <CardExpiryElement
                            required
                            options={{
                              placeholder: "MM/YY",
                              style: {
                                base: {
                                  fontSize: "16px",
                                },
                                invalid: {
                                  color: "#9e2146",
                                },
                              },
                            }}
                          />
                        </div>
                      </MDBox>
                      <MDBox mb={2}>
                        <label style={{ fontSize: "12px" }}>CVC/CVV</label>
                        <div
                          style={{
                            border: "1px solid #d1d5db",
                            borderRadius: "5px",
                            padding: "10px",
                          }}
                        >
                          <CardCvcElement
                            required
                            options={{
                              placeholder: "...",
                              style: {
                                base: {
                                  fontSize: "16px",
                                },
                                invalid: {
                                  color: "#9e2146",
                                },
                              },
                            }}
                          />
                        </div>
                      </MDBox>

                      <MDBox
                        mt={4}
                        mb={1}
                        sx={{ display: "flex", gap: "10px", justifyContent: "end" }}
                      >
                        <MDButton variant="gradient" color="info" onClick={handleClose}>
                          Cancel
                        </MDButton>
                        <MDButton variant="gradient" color="info" type="submit" disabled={loading}>
                          Submit
                        </MDButton>
                      </MDBox>
                    </form>
                  )}
                </ElementsConsumer>
              </Elements>
            )}
          </MDBox>
        </Card>
      </Modal>
    </>
  );
};

export default Payments;
