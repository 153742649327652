import { useState } from "react";
import { Box, TextField } from "@mui/material";
import { Autocomplete } from "@react-google-maps/api";

const TextFields = ({ question, index, handleTextFields, handleLocationChange, isDisabled }) => {
  const [autocomplete, setAutocomplete] = useState(null);

  const onLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      const city = place?.address_components?.find((component) =>
        component.types.includes("locality")
      )?.long_name;
      handleLocationChange("location", index, city);
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={1} marginTop={1}>
      <TextField
        variant="outlined"
        type="text"
        name="specific_role"
        placeholder="Enter Specific Role"
        value={question.specific_role}
        onChange={(event) => handleTextFields(index, event)}
        disabled={isDisabled}
        fullWidth
      />
      <Autocomplete
        onLoad={onLoad}
        onPlaceChanged={onPlaceChanged}
        options={{
          types: ["(cities)"],
          componentRestrictions: { country: ["us", "gb", "ca"] },
        }}
      >
        <TextField
          variant="outlined"
          type="text"
          name="location"
          placeholder="Enter Location"
          value={question.location}
          onChange={(event) => handleTextFields(index, event)}
          disabled={isDisabled}
          fullWidth
        />
      </Autocomplete>

      <TextField
        variant="outlined"
        type="text"
        name="company_name"
        placeholder="Enter Company Name"
        value={question.company_name}
        onChange={(event) => handleTextFields(index, event)}
        disabled={isDisabled}
        fullWidth
      />
    </Box>
  );
};

export default TextFields;
