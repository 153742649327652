import React from "react";
import { Box, Skeleton } from "@mui/material";

const TableShimmer = () => {
  return (
    <Box
      sx={{
        position: "absolute", // Ensures it overlays without affecting layout
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.6)", // Semi-transparent overlay
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 10, // Ensures it appears above the table but below higher elements
      }}
    >
      {/* Table Header Shimmer */}
      <Skeleton variant="rectangular" width="80%" height={40} sx={{ borderRadius: 1, mb: 2 }} />

      {/* Table Rows Shimmer */}
      {[1, 2, 3, 4, 5].map((index) => (
        <Box key={index} display="flex" alignItems="center" gap={2} mb={1} width="80%">
          <Skeleton variant="rectangular" width="25%" height={35} sx={{ borderRadius: 1 }} />
          <Skeleton variant="rectangular" width="25%" height={35} sx={{ borderRadius: 1 }} />
          <Skeleton variant="rectangular" width="25%" height={35} sx={{ borderRadius: 1 }} />
          <Skeleton variant="rectangular" width="25%" height={35} sx={{ borderRadius: 1 }} />
        </Box>
      ))}
    </Box>
  );
};

export default TableShimmer;
