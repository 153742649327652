import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import Loader from "components/Loader";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getDashboardTrainingStats,
  getDashboardSimulationStats,
  getOrgResumeStats,
} from "store/Slices/profileSlice";
import {
  loginFrequency,
  simulationScorePerUser,
  pRoundScorePerUser,
  incompletionRates,
  simulationSessionTopScore,
  pRoundSessionTopScore,
  timeSpent,
  careerReadiness,
  summaryReport,
  monthlyAvgScore,
  getJoinedUsers,
  getUserAvgScore,
  getUserCompletedResumes,
  getUserFeedbackSatisfaction,
  getUserSimulationStats,
  getUserSimultionStatus,
  getUserTrainingStats,
  getUserTrainingStatus,
  userResumeStats,
} from "store/Slices/usersSlice";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "jspdf-autotable";
import UserStatsComponent from "components/UserStatsComponent";
import { IqSearchCount } from "store/Slices/usersSlice";
import { IqAlertCount } from "store/Slices/usersSlice";
import { IqAppliedJobCount } from "store/Slices/usersSlice";
ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const Analytics = ({ featurePermissions }) => {
  const dispatch = useDispatch();
  const { joinedUsers } = useSelector((state) => state.userManagment);
  const { statsLoading, loading } = useSelector((state) => state.users);
  const [singleUser, setSingleUser] = useState(null);

  const { dashboardTrainingStats, dashboardSimulationStats, orgResume } = useSelector(
    (state) => state.orgProfile
  );

  const getJoinedUsersFunction = () => {
    dispatch(getJoinedUsers())
      .unwrap()
      .then((res) => {})
      .catch((rejected) => toast.error(rejected?.response?.data?.detail));
  };

  useEffect(() => {
    dispatch(getDashboardTrainingStats());
    dispatch(getDashboardSimulationStats());
    dispatch(getOrgResumeStats());
    getJoinedUsersFunction();
  }, []);

  useEffect(() => {
    if (singleUser) {
      dispatch(getUserTrainingStats(singleUser));
      dispatch(getUserSimulationStats(singleUser));
      dispatch(getUserTrainingStatus(singleUser));
      dispatch(getUserSimultionStatus(singleUser));
      dispatch(userResumeStats(singleUser));
      dispatch(getUserCompletedResumes(singleUser));
      dispatch(getUserAvgScore(singleUser));
      dispatch(getUserFeedbackSatisfaction(singleUser));

      dispatch(monthlyAvgScore(singleUser));
      dispatch(careerReadiness(singleUser));
      dispatch(summaryReport(singleUser));
      dispatch(loginFrequency(singleUser));
      dispatch(timeSpent(singleUser));
      dispatch(simulationScorePerUser(singleUser));
      dispatch(pRoundScorePerUser(singleUser));
      dispatch(simulationSessionTopScore(singleUser));
      dispatch(pRoundSessionTopScore(singleUser));
      dispatch(incompletionRates(singleUser));
      dispatch(IqSearchCount(singleUser));
      dispatch(IqAlertCount(singleUser));
      dispatch(IqAppliedJobCount(singleUser));
    }
  }, [singleUser]);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <MDBox
          mx={2}
          mt={-3}
          mb={5}
          py={2}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <MDTypography variant="h6" color="white">
            Analytics
          </MDTypography>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Practice Rounds"
                count={dashboardTrainingStats?.total_sessions}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Interviews Taken"
                count={dashboardSimulationStats?.total_sessions}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Resume's Started"
                count={orgResume}
              />
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={2} my={3}>
          <MDBox mx={2} mt={4} display="flex" justifyContent="space-between" alignItems="center">
            <MDTypography variant="h6">User's Reports</MDTypography>
          </MDBox>
          <Grid item xs={12} md={12} lg={12}>
            <MDBox mb={2}>
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select User</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="account"
                    value={singleUser}
                    onChange={(e) => setSingleUser(e.target.value)}
                    label="Select User"
                  >
                    {joinedUsers?.map((user, index) => {
                      return (
                        <MenuItem key={index} value={user?.customer_id}>
                          {user?.customer_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </MDBox>
            {statsLoading || loading ? (
              <Loader />
            ) : (
              <>{singleUser && <UserStatsComponent featurePermissions={featurePermissions} />}</>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Analytics;
