import Repository from "./Repository";
const CREATE_REPORT = "/organization-reporting/create";
const GET_REPORTS = "/organization-reporting/read";

const ReportAnalyticsRepository = {
  createReport(payload) {
    // console.log(payload, "payload");
    return Repository.post(`${CREATE_REPORT}`, payload);
  },
  getReports() {
    return Repository.get(`${GET_REPORTS}`);
  },
  deleteReport(id) {
    return Repository.delete(`/organization-reporting/delete/${id}`);
  },
  singleReport(id) {
    return Repository.get(`/organization-reporting/get/${id}`);
  },
};

export default ReportAnalyticsRepository;
