import { Avatar } from "@mui/material";

export const ChatAvatar = ({ url }) => {
  return (
    <Avatar
      alt=""
      src={url}
      sx={{
        width: 30,
        height: 30,
        marginTop: "4px",
        mx: 1,
        border: "1px solid #ccc",
      }}
    />
  );
};
