import { Box, Card, Divider, Modal, Paper, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import React from "react";
import ReactMarkdown from "react-markdown";

const ResumeFeedbackModal = ({ modal, handleClose, storeResumeFeedback }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    maxHeight: "80vh", // Limits the height to 80% of the viewport height
    boxShadow: 24,
    p: 4,
    overflowY: "auto", // Enables scrolling when content overflows
  };
  return (
    <Modal
      open={modal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <MDBox>
          <MDTypography variant="h6">Resume Feedback</MDTypography>
        </MDBox>
        <MDBox mx={1} p={1} mt={1}>
          <MDBox>
            <MDTypography variant="h5" m={1}>
              Feedback:
            </MDTypography>
            <hr />
            {/* <MDTypography variant="h6" my={2} mx={1}> */}
            {/* <ReactMarkdown>{storeResumeFeedback?.feedback}</ReactMarkdown> */}
            <Box>
              {storeResumeFeedback?.feedback &&
                Object?.entries(eval("(" + storeResumeFeedback.feedback + ")"))?.map(
                  ([key, value]) => (
                    <Paper
                      key={key}
                      elevation={2}
                      sx={{ my: 3, p: 2, borderRadius: 2, backgroundColor: "background.paper" }}
                    >
                      {key === "summary" ? (
                        <Box sx={{ pb: 2 }}>
                          <Typography
                            variant="h6"
                            fontWeight="bold"
                            color="text.primary"
                            sx={{ px: 2 }}
                          >
                            {key.replace(/\b\w/g, (char) => char.toUpperCase())} of Feedback
                          </Typography>
                          <Typography variant="body2" color="black" sx={{ mx: 2 }}>
                            {value}
                          </Typography>
                        </Box>
                      ) : (
                        <Box sx={{ pb: 2 }}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              px: 2,
                            }}
                          >
                            <Typography variant="h6" fontWeight="bold" color="text.primary">
                              {key
                                ?.replace(/_/g, " ")
                                ?.replace(/\b\w/g, (char) => char?.toUpperCase())}
                            </Typography>
                            <Box
                              sx={{
                                backgroundColor: "#1973e8",
                                borderRadius: "8px",
                                px: 2,
                                py: 0.5,
                              }}
                            >
                              <Typography
                                variant="body2"
                                color="common.white"
                                sx={{ fontWeight: "bold" }}
                              >
                                Score: {value?.score}
                              </Typography>
                            </Box>
                          </Box>
                          {/* Display the comments */}
                          <Typography variant="body2" color="black" sx={{ mx: 2, mt: 2 }}>
                            {value?.feedback}
                          </Typography>
                        </Box>
                      )}
                      <Divider />
                    </Paper>
                  )
                )}
            </Box>

            {/* </MDTypography> */}
          </MDBox>

          <MDBox
            mt={3}
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: "start",
              alignItems: "center",
              background: "#9eeaf9",
              padding: "10px",
              borderRadius: "12px",
            }}
          >
            <h5 className="my-0">Nexa Resume Score:</h5>
            <span className="score">{storeResumeFeedback?.score}</span>
          </MDBox>
          <MDBox mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
            <MDButton variant="gradient" color="info" onClick={handleClose}>
              Close
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
    </Modal>
  );
};

export default ResumeFeedbackModal;
