import { AttachFile, Send, SentimentSatisfiedAlt, Clear } from "@mui/icons-material";
import { CircularProgress, Icon, IconButton, Chip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import MessageInput from "./MessageInput";
import { useDispatch, useSelector } from "react-redux";
import { updateChatMessages, updateConversations, sendMessageAction } from "store/Slices/ChatSlice";
import { sendMessage } from "config/socketConfig";
import EmojiPicker from "emoji-picker-react";
import { toast } from "react-toastify";

const ChatFooter = () => {
  const dispatch = useDispatch();
  const fileRef = useRef(null);
  const emojiRef = useRef(null);
  const { activeConversation } = useSelector((state) => state.chats);
  const [sendLoader, setSendLoader] = useState(false);
  let [isSmiley, setisSmiley] = useState(false);
  const [message, setMessage] = useState("");
  const [media, setMedia] = useState(null);

  const handleClick = () => {
    fileRef.current.click();
  };

  const handleClearFile = () => {
    setMedia(null);
    if (fileRef.current) {
      fileRef.current.value = null;
    }
  };

  const handleEmojiSelect = (emoji) => {
    setMessage((prevMessage) => prevMessage + emoji.emoji);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiRef.current && !emojiRef.current.contains(event.target)) {
        setisSmiley(false);
      }
    };
    if (isSmiley) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSmiley]);

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setSendLoader(true);
          let formData = new FormData();
          formData.append("chat_id", activeConversation?.id);
          formData.append("content", message);
          formData.append("media", media == null ? "" : media);
          if (media != null && message !== "") {
            formData.append("message_type", "both");
          } else if (media != null && message === "") {
            formData.append("message_type", "media");
          } else if (message !== "" && media == null) {
            formData.append("message_type", "text");
          } else {
            toast.error("Please upload a file or enter a message");
            setSendLoader(false);
            return;
          }
          dispatch(
            sendMessageAction({
              formData,
              onSuccess: (data) => {
                if (data.status === true) {
                  let senderMessages = {
                    ...data?.response,
                    is_my_message: true,
                    updatedConversation: {
                      ...activeConversation,
                      last_message: data?.response,
                      unread_messages: data?.response?.unread_messages,
                    },
                  };
                  let receiverMessages = {
                    ...data?.response,
                    is_my_message: false,
                    updatedConversation: {
                      ...activeConversation,
                      last_message: data?.response,
                      unread_messages: data?.response?.unread_messages,
                      members: [
                        {
                          name: activeConversation?.is_group
                            ? activeConversation?.members[0]?.name
                            : data?.response?.sender_name,
                          image: activeConversation?.is_group
                            ? activeConversation?.members[0]?.image
                            : data?.response?.sender_image,
                          user_id: activeConversation?.is_group
                            ? activeConversation?.members[0]?.user_id
                            : data?.response?.sender_id,
                        },
                      ],
                    },
                  };
                  console.log(receiverMessages, "receiverMessages");
                  dispatch(updateChatMessages(senderMessages));
                  dispatch(updateConversations(senderMessages?.updatedConversation));
                  sendMessage(receiverMessages);
                  setMessage("");
                  setMedia(null);
                  setSendLoader(false);
                } else {
                  setSendLoader(false);
                }
              },
            })
          );
        }}
      >
        <div className="d-flex align-items-center w-100 bg-white rounded position-relative">
          <IconButton onClick={() => setisSmiley((prevState) => !prevState)}>
            <SentimentSatisfiedAlt />
          </IconButton>

          {isSmiley && (
            <div
              style={{
                position: "absolute",
                bottom: "50px",
                right: "0",
                left: "0",
                zIndex: 999,
                backgroundColor: "#fff",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
              }}
              ref={emojiRef}
            >
              <EmojiPicker onEmojiClick={(e) => handleEmojiSelect(e)} />
            </div>
          )}

          <IconButton onClick={handleClick}>
            <AttachFile />
            <input
              type="file"
              onChange={(e) => {
                const file = e.target.files[0];
                if (file) {
                  const fileSizeInMB = file.size / 1024 / 1024; // Convert bytes to MB
                  if (fileSizeInMB > 1) {
                    toast.error("File size must be less than 1 MB");
                    e.target.value = null; // Reset the input if the file is too large
                  } else {
                    setMedia(file);
                  }
                }
              }}
              ref={fileRef}
              hidden
              accept=".pdf, .docx, .doc, .zip, .txt, .jpg, .jpeg, .png, .svg, .mp4, .webm, .jfif"
            />
          </IconButton>

          <MessageInput
            sendLoader={sendLoader}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />

          <IconButton disabled={sendLoader} variant="contained" type="submit">
            {sendLoader ? <CircularProgress size={20} /> : <Send />}
          </IconButton>
        </div>
      </form>

      {media && (
        <Chip
          label={media.name.length > 10 ? `${media.name.slice(0, 10)}...` : media.name}
          onDelete={handleClearFile}
          sx={{
            backgroundColor: "#e9ebed",
            color: "#626262",
            marginTop: "10px",
            marginLeft: "10px",
          }}
          deleteIcon={<Clear style={{ color: "red" }} />}
        />
      )}
    </>
  );
};

export default ChatFooter;
