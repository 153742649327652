import React, { useState } from "react";

const TruncateText = ({ text }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const truncateText = (text) => {
    const words = text.split(" ");
    if (words.length > 3) {
      return words.slice(0, 3).join(" ") + " ...";
    }
    return text;
  };

  const handleToggle = (event) => {
    event.preventDefault();
    setIsTruncated(!isTruncated);
  };

  const words = text.split(" ");
  const truncatedText = truncateText(text);

  return (
    <div>
      <span>{isTruncated ? truncatedText : text}</span>
      {words.length > 3 && (
        <span className="truncate-text" onClick={handleToggle}>
          {isTruncated ? " See More" : " See Less"}
        </span>
      )}
    </div>
  );
};

export default TruncateText;
