import { ArrowBackIos } from "@mui/icons-material";
import { Avatar, Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateActiveConversation } from "store/Slices/ChatSlice";
import dumyUser from "../../assets/images/dumyUser.png";
const ChatHeader = () => {
  const dispatch = useDispatch();
  const { activeConversation } = useSelector((state) => state.chats);
  return (
    <Box
      sx={{
        p: 2,
        background: "#000",
        borderRadius: "12px 12px 0 0",
      }}
      className="d-flex align-items-center"
    >
      <ArrowBackIos
        onClick={() => {
          dispatch(updateActiveConversation(null));
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          color: "#fff",
          fontSize: 20,
          mr: 2,
        }}
      />
      <Avatar
        alt=""
        src={
          activeConversation?.members[0]?.image ? activeConversation?.members[0]?.image : dumyUser
        }
        sx={{ height: 40, width: 40 }}
      />
      <div className="d-flex flex-col">
        <Typography variant="h5" sx={{ m: 0, color: "#fff", fontWeight: 700, mx: 2, ml: 3 }}>
          {activeConversation?.members[0]?.name}
        </Typography>
      </div>
    </Box>
  );
};

export default ChatHeader;
