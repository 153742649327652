import { Box, Button, Card, Grid, Modal, Typography } from "@mui/material";
import Loader from "components/Loader";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { addSubOrgUser } from "components/schema";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getSuborganizationUser } from "store/Slices/suborganizationSlice";
import UndoIcon from "@mui/icons-material/Undo";

const SubOrganizationsDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, subOrganizationUsers } = useSelector((state) => state.subOrganization);
  const [open, setOpen] = useState(false);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const handleClose = () => {
    resetForm();
    setOpen(false);
  };
  const pillSx = {
    display: "inline-block",
    padding: "0.2em 0.6em",
    borderRadius: "9999px",
    fontSize: "0.75rem",
    fontWeight: 500,
    textAlign: "center",
    color: "white",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: { email: "", sub_organization_user_name: "" },
    validationSchema: addSubOrgUser,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let payload = {
        email: values.email,
        name: values.sub_organization_user_name,
      };
      console.log(payload, "payload");
      handleClose();
      resetForm();
    },
  });

  useEffect(() => {
    setColumns([
      {
        Header: "Sub Organization's User Name",
        accessor: "customer_name",
        align: "left",
      },
      { Header: "Email", accessor: "email", align: "center" },
      { Header: "Sub Organization", accessor: "organization_name", align: "center" },
      { Header: "Created At", accessor: "createdAt", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
    ]);
    const customAddAdmin =
      subOrganizationUsers &&
      subOrganizationUsers.length >= 1 &&
      subOrganizationUsers.map((data, index) => {
        return {
          customer_name: (
            <MDTypography
              component="a"
              href="#"
              variant="button"
              color="text"
              fontWeight="medium"
              key={index + 100}
            >
              {data?.customer_name}
            </MDTypography>
          ),
          email: (
            <MDTypography
              component="a"
              href="#"
              variant="button"
              color="text"
              fontWeight="medium"
              textAlign="left"
            >
              {data?.email}
            </MDTypography>
          ),
          organization_name: (
            <MDTypography
              component="a"
              href="#"
              variant="button"
              color="text"
              fontWeight="medium"
              textAlign="left"
            >
              {data?.organization_name}
            </MDTypography>
          ),
          createdAt: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {moment(data?.created_on).format("DD MMM YYYY")}
            </MDTypography>
          ),
          status: (
            <Typography
              component="a"
              href="#"
              variant="caption"
              color="textPrimary"
              fontWeight="medium"
            >
              <Box
                component="span"
                sx={{
                  ...pillSx,
                  backgroundColor:
                    data?.status === "joined"
                      ? "#388e3c"
                      : data?.status === "revoked"
                      ? "#ef5350"
                      : data?.status === "pending"
                      ? "#f57c00"
                      : "#ab47bc",
                  color: "#fff",
                }}
              >
                {data?.status}
              </Box>
            </Typography>
          ),
          updatedon: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {moment(data?.updated_on).format("DD MMM YYYY")}
            </MDTypography>
          ),
        };
      });
    setRows(Array.isArray(customAddAdmin) ? customAddAdmin : []);
  }, [subOrganizationUsers]);

  useEffect(() => {
    dispatch(getSuborganizationUser({ id }));
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    boxShadow: 24,
    p: 4,
  };

  return (
    <DashboardLayout>
      {isLoading && <Loader />}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
              >
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    color: "#fff",
                    bgcolor: "dark",
                    "&:hover": {
                      bgcolor: "darkslategray",
                    },
                  }}
                  onClick={() => navigate(-1)}
                >
                  <UndoIcon fontSize="large" sx={{ mt: -0.25, mr: 0.5 }} />
                  Back
                </Button>
                <MDTypography variant="h6" color="white">
                  SUB ORGANIZATION'S USERS
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {subOrganizationUsers && subOrganizationUsers.length >= 1 ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                    checkBoxes={false}
                  />
                ) : (
                  <MDTypography
                    component="h2"
                    href="#"
                    color="text"
                    fontWeight="bold"
                    sx={{
                      textAlign: "center",
                      my: 10,
                    }}
                  >
                    YOU DON'T HAVE ANY USER IN SUB ORGANIZATION
                  </MDTypography>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style}>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1} mb={1}>
              Add Sub Organization User
            </MDTypography>
          </MDBox>
          <MDBox mx={1} p={1} mt={1}>
            <form onSubmit={handleSubmit}>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Sub Organization User Name"
                  name="sub_organization_user_name"
                  value={values.sub_organization_user_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                />
                {errors.sub_organization_user_name && touched.sub_organization_user_name ? (
                  <MDTypography color="error" sx={{ fontSize: "13px" }}>
                    {<p>{errors.sub_organization_user_name}</p>}
                  </MDTypography>
                ) : null}
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                />
                {errors.email && touched.email ? (
                  <MDTypography color="error" sx={{ fontSize: "13px" }}>
                    {<p>{errors.email}</p>}
                  </MDTypography>
                ) : null}
              </MDBox>

              <MDBox mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
                <MDButton variant="gradient" color="info" onClick={handleClose}>
                  Cancel
                </MDButton>
                <MDButton variant="gradient" color="info" type="submit">
                  Save
                </MDButton>
              </MDBox>
            </form>
          </MDBox>
        </Card>
      </Modal>
    </DashboardLayout>
  );
};

export default SubOrganizationsDetail;
