/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default styled(Drawer)(({ theme, ownerState }) => {
  const { orgTheme } = useSelector((state) => state.oraganizationTheme);
  const { palette, boxShadows, transitions, breakpoints, functions } = theme;
  const { transparentSidenav, whiteSidenav, miniSidenav, darkMode } = ownerState;
  const [backgroundValue, setBackgroundValue] = useState("");

  const sidebarWidth = 250;
  const { transparent, gradients, white, background } = palette;
  const { xxl } = boxShadows;
  const { pxToRem, linearGradient } = functions;

  // let backgroundValue = darkMode
  //   ? background.sidenav
  //   : linearGradient(gradients.dark.main, gradients.dark.state);

  // let backgroundValue =
  //   orgTheme?.sidebar_type && orgTheme?.sidebar_type !== "transparent"
  //     ? orgTheme?.sidebar_type
  //     : linearGradient(gradients.dark.main, gradients.dark.state);

  // if (transparentSidenav) {
  //   backgroundValue = transparent.main;
  // } else if (whiteSidenav) {
  //   backgroundValue = white.main;
  // }
  // let backgroundValue;

  // if (transparentSidenav) {
  //   // console.log("first ifff");
  //   setBackgroundValue(transparent.main);
  // } else if (whiteSidenav) {
  //   // console.log("2nd ifff");
  //   // backgroundValue = white.main;
  //   setBackgroundValue(white.main);
  // } else {
  //   setBackgroundValue(white.main);
  //   // backgroundValue = linearGradient(gradients.dark.main, gradients.dark.state);
  // }
  // // console.log({ sidebar: orgTheme?.sidebar_type });

  useEffect(() => {
    if (orgTheme) {
      setBackgroundValue(orgTheme?.sidebar_type);
    }
  }, [orgTheme?.sidebar_type]);

  // styles for the sidenav when miniSidenav={false}
  const drawerOpenStyles = () => ({
    background: backgroundValue,
    transform: "translateX(0)",
    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),
    backdropFilter: "blur(10px)",

    [breakpoints.up("xl")]: {
      boxShadow: transparentSidenav ? "none" : xxl,
      marginBottom: transparentSidenav ? 0 : "inherit",
      left: "0",
      width: sidebarWidth,
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
  });

  // styles for the sidenav when miniSidenav={true}
  const drawerCloseStyles = () => ({
    background: backgroundValue,
    transform: `translateX(${pxToRem(-320)})`,
    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),
    backdropFilter: "blur(10px)",
    [breakpoints.up("xl")]: {
      boxShadow: transparentSidenav ? "none" : xxl,
      marginBottom: transparentSidenav ? 0 : "inherit",
      left: "0",
      width: pxToRem(96),
      overflowX: "hidden",
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.shorter,
      }),
    },
  });

  return {
    "& .MuiDrawer-paper": {
      boxShadow: xxl,
      border: "none",

      ...(miniSidenav ? drawerCloseStyles() : drawerOpenStyles()),
    },
  };
});
