import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

export default function DropDowns({
  rolesDetails,
  question,
  categoriesDetails,
  Questions,
  setQuestions,
  index,
  questionsType,
  isDisabled,
}) {
  const [matchingCategories, setMatchingCategories] = useState([]);
  const [matchingSubCategories, setMatchingSubCategories] = useState([]);
  const [showSubCategories, setShowSubCategories] = useState(false);
  const [categoryLabel, setCategoryLabel] = useState(null);
  const { allSubCategories } = useSelector((state) => state.roleCategories);

  useEffect(() => {
    const initialMatchingCategories = categoriesDetails
      .filter(
        (category) =>
          category.jobRoleName ===
          rolesDetails.find((role) => role?.value === question?.roleId)?.label
      )
      .map((category) => ({
        label: category.display_name,
        value: category.id,
      }));
    setMatchingCategories(initialMatchingCategories);
  }, [categoriesDetails, rolesDetails, question?.roleId]);

  useEffect(() => {
    if (categoryLabel) {
      const normalizeString = (str) => str?.replace(/-/g, " ")?.toLowerCase();
      const initialMatchingSubCategories = allSubCategories
        .filter((category) => {
          return normalizeString(category?.categoryName) === normalizeString(categoryLabel);
        })
        .map((category) => ({
          label: category?.display_name,
          value: category?.id,
        }));
      setMatchingSubCategories(initialMatchingSubCategories);
    }
  }, [allSubCategories, categoryLabel]);

  const handleQuestionChange = (index, field, selectedOption) => {
    const updatedQuestions = Array.isArray(Questions) ? [...Questions] : { ...Questions };
    if (field === "roleId" && selectedOption?.value) {
      const matchedCategories = categoriesDetails
        .filter(
          (category) =>
            category?.jobRoleName ===
            rolesDetails?.find((role) => role?.value === selectedOption?.value)?.label
        )
        .map((category) => ({
          label: category?.display_name,
          value: category?.id,
        }));
      setMatchingCategories(matchedCategories);
      setMatchingSubCategories([]);
      setShowSubCategories(selectedOption.label === "Developer" ? true : false);
      if (index !== "") {
        updatedQuestions[index]["role_category_id"] = "";
        updatedQuestions[index]["role_subcategory_id"] = null;
      } else {
        updatedQuestions["role_category_id"] = "";
        updatedQuestions["role_subcategory_id"] = null;
      }
    }
    if (field === "role_category_id" && showSubCategories) {
      setCategoryLabel(selectedOption?.label);
      if (index !== "") {
        updatedQuestions[index]["role_subcategory_id"] = null;
      } else {
        updatedQuestions["role_subcategory_id"] = null;
      }
    }
    if (index !== "") {
      updatedQuestions[index][field] = selectedOption?.value;
    } else {
      updatedQuestions[field] = selectedOption?.value;
    }
    setQuestions(updatedQuestions);
  };

  return (
    <>
      <Select
        options={rolesDetails}
        value={rolesDetails?.find((role) => role?.value === question?.roleId) || null}
        onChange={(selectedOption) => handleQuestionChange(index, "roleId", selectedOption)}
        placeholder="Select Role"
        isClearable
        required
        isDisabled={isDisabled}
      />
      <div style={{ marginTop: "10px" }}>
        <Select
          options={matchingCategories}
          value={
            question?.role_category_id
              ? matchingCategories?.find(
                  (category) => category?.value === question?.role_category_id
                )
              : null
          }
          onChange={(selectedOption) =>
            handleQuestionChange(index, "role_category_id", selectedOption)
          }
          placeholder="Select Role Category"
          isClearable
          required
          isDisabled={isDisabled || matchingCategories.length === 0}
        />
      </div>
      {showSubCategories && (
        <div style={{ marginTop: "10px" }}>
          <Select
            options={matchingSubCategories}
            value={
              question?.role_subcategory_id
                ? matchingSubCategories?.find(
                    (subCategory) => subCategory?.value === question?.role_subcategory_id
                  )
                : null
            }
            onChange={(selectedOption) =>
              handleQuestionChange(index, "role_subcategory_id", selectedOption)
            }
            placeholder="Select Role Sub Category"
            isClearable
            required={showSubCategories && matchingSubCategories.length > 0}
            isDisabled={isDisabled || matchingSubCategories.length === 0}
          />
        </div>
      )}
      <div style={{ marginTop: "10px" }}>
        <Select
          options={questionsType}
          value={questionsType?.find((role) => role?.value === question?.question_type_id) || null}
          onChange={(selectedOption) =>
            handleQuestionChange(index, "question_type_id", selectedOption)
          }
          placeholder="Select Question Category"
          isClearable
          required
          isDisabled={isDisabled}
        />
      </div>
    </>
  );
}
