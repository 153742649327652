import Repository from "./Repository";
const THEME = "organization/theming";

const themeRepository = {
  updateTheme(payload) {
    let themeForm = new FormData();
    if (payload.icon) {
      themeForm.append("icon", payload.icon);
    }
    if (payload.sidebar_type) {
      themeForm.append("sidebar_type", payload.sidebar_type);
    }
    if (payload.sidebar_color) {
      themeForm.append("sidebar_color", payload.sidebar_color);
    }

    return Repository.put(`${THEME}`, themeForm);
  },
  getTheme() {
    return Repository.get(`${THEME}`);
  },
  createQuote(payload) {
    const data = {
      quote: payload,
    };
    return Repository.post(`/organization-quote/create`, data);
  },
  getQuote() {
    return Repository.get(`/organization-quote/latest`);
  },
};

export default themeRepository;
