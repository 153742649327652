import Repository from "./Repository";
const NEW_ORGANIZATION = "organization/register";
const LOGIN_ORG = "organization/login";
const RESET_PASSWORD = "organization/reset-password";
const REGISTER_SUBADMIN = "/organization/sub_admin/register";
const LOGOUT_USER = "/es/logout";

const authRepository = {
  createNewOraganization(payload) {
    return Repository.post(`${NEW_ORGANIZATION}`, payload);
  },
  authenticateOrg(payload) {
    // console.log("logging in", payload);
    return Repository.post(`${LOGIN_ORG}`, payload);
  },
  resetPassword(payload) {
    // console.log({ payload });
    let { token, ...rest } = payload;
    return Repository.post(`${RESET_PASSWORD}/${token}`, rest);
  },
  resetPasswordLink(payload) {
    return Repository.post(`${RESET_PASSWORD}`, payload);
  },
  Register_subAdmin(payload) {
    return Repository.post(`${REGISTER_SUBADMIN}`, payload);
  },
  logout() {
    return Repository.put(`${LOGOUT_USER}`);
  },
};

export default authRepository;
