import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";
const reportRepo = RepositoryFactory.get("ReportAnalytics");

export const createReport = createAsyncThunk(
  "ReportAnalytics/createReport",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      // console.log(payload, "payload");
      const { data } = await reportRepo.createReport(payload);
      toast.success(data.detail);
      onSuccess();
      // console.log(data);
    } catch (err) {
      console.log("err: ", err);
      toast.error(err?.response?.data?.detail);
      return rejectWithValue(err);
    }
  }
);

export const getReports = createAsyncThunk(
  "ReportAnalytics/getReports",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await reportRepo.getReports();
      // console.log(data, "databyahsan");
      return data?.reports;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getSingleReport = createAsyncThunk(
  "ReportAnalytics/singleReport",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await reportRepo.singleReport(id);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const deleteSingleReport = createAsyncThunk(
  "ReportAnalytics/deleteReport",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await reportRepo.deleteReport(id);
      // console.log(data, "deleted successfully");
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  reports: [],
  singleFetchedReport: null,
  loading: false,
  error: null,
};

const ReportAnalyticsSlice = createSlice({
  name: "ReportAnalytics",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getReports.pending, (state) => {
        // console.log("Running");
        state.loading = true;
        state.error = null;
      })
      .addCase(getReports.fulfilled, (state, action) => {
        state.loading = false;
        state.reports = action.payload;
      })
      .addCase(getReports.rejected, (state, action) => {
        // console.log("Error");
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getSingleReport.pending, (state) => {
        // console.log("Running");
        state.loading = true;
        state.error = null;
      })
      .addCase(getSingleReport.fulfilled, (state, action) => {
        state.loading = false;
        const updatedReport = { ...action.payload };
        if (typeof updatedReport.report === "string") {
          try {
            updatedReport.report = JSON.parse(updatedReport.report);
          } catch (error) {
            console.error("Failed to parse report JSON string:", error);
          }
        }
        state.singleFetchedReport = updatedReport;
      })
      .addCase(getSingleReport.rejected, (state, action) => {
        // console.log("Error");
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteSingleReport.pending, (state) => {
        // console.log("Running");
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSingleReport.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteSingleReport.rejected, (state, action) => {
        // console.log("Error");
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createReport.pending, (state) => {
        // console.log("Running");
        state.loading = true;
        state.error = null;
      })
      .addCase(createReport.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createReport.rejected, (state, action) => {
        // console.log("Error");
        state.loading = false;
        state.error = action.error.message;
      });
    //   .addCase(getInvoices.pending, (state) => {
    //     // console.log("Running");
    //     state.loading = true;
    //     state.error = null;
    //   })
    //   .addCase(getInvoices.fulfilled, (state, action) => {
    //     // console.log(action.payload, "action.payloadaction.payload");
    //     state.loading = false;
    //     state.invoices = action.payload;
    //   })
    //   .addCase(getInvoices.rejected, (state, action) => {
    //     // console.log("Error");
    //     state.loading = false;
    //     state.error = action.error.message;
    //   });
  },
});

export default ReportAnalyticsSlice.reducer;
