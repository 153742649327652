import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";
const subOrgRepo = RepositoryFactory.get("subOrg");

export const postInviteSubOrg = createAsyncThunk(
  "subOrganization/postInviteSubOrg",
  async ({ payload, pagination }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await subOrgRepo.invite(payload);
      await dispatch(getAllSubOrganizations(pagination));
      // if (data.already_registered?.length > 0) {
      //   toast.error(`Email:${data.already_registered[0]} already registered `);
      // }
      // if (data.invited_by_other_org?.length > 0) {
      //   toast.error(`Email:${data.already_registered[0]} already invited by other organization`);
      // }
      // if (data.new_added_emails?.length > 0) {
      //   toast.success(`Email:${data.new_added_emails[0]} Invited Sucessfully`);
      // }
      // if (data.refresh_invites?.length > 0) {
      //   toast.success(`Invitation Resent to Email:${data.refresh_invites[0]}`);
      // }
      toast.success("Invitation sent successfully");
      return data;
    } catch (err) {
      toast.error(err?.response?.data?.detail);
      return rejectWithValue(err);
    }
  }
);

export const getAllSubOrganizations = createAsyncThunk(
  "subOrganization/getAll",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await subOrgRepo.getAll(payload);

      return data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const revokeInvitaion = createAsyncThunk(
  "subOrganization/revokeInvitaion",
  async ({ payload, pagination }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await subOrgRepo.revoke(payload);
      await dispatch(getAllSubOrganizations(pagination));
      toast.success(data?.detail);
      return;
    } catch (err) {
      toast.error(err?.response?.data?.detail);
      return rejectWithValue(err);
    }
  }
);

export const activateInvitation = createAsyncThunk(
  "subOrganization/activateInvitation",
  async ({ payload, pagination }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await subOrgRepo.activateRevoked(payload);
      await dispatch(getAllSubOrganizations(pagination));
      toast.success(data?.detail);
      return;
    } catch (err) {
      toast.error(err?.response?.data?.detail);
      return rejectWithValue(err);
    }
  }
);

export const resendInvitation = createAsyncThunk(
  "subOrganization/resendInvitation",
  async (email, { rejectWithValue }) => {
    try {
      const { data } = await subOrgRepo.resendInvitation({ email });
      toast.success(data?.detail);
      return;
    } catch (err) {
      toast.error(err?.response?.data?.detail);
      return rejectWithValue(err);
    }
  }
);

export const deleteInvitation = createAsyncThunk(
  "subOrganization/deleteInvitation",
  async ({ payload, pagination }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await subOrgRepo.delete(payload);
      await dispatch(getAllSubOrganizations(pagination));
      toast.success(data?.detail);
      return data;
    } catch (err) {
      toast.error(err?.response?.data?.detail);
      return rejectWithValue(err);
    }
  }
);

export const getSuborganizationUser = createAsyncThunk(
  "subOrganization/getSuborganizationUser",
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await subOrgRepo.getsubOrgUser(id);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  isLoading: false,
  subOrganizations: [],
  paginationData: null,
  subOrganizationUsers: [],
};

const suborganizationSlice = createSlice({
  name: "subOrganization",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postInviteSubOrg.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postInviteSubOrg.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(postInviteSubOrg.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getAllSubOrganizations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllSubOrganizations.fulfilled, (state, action) => {
        let { sub_organizations, page, size, total, total_pages } = action.payload;
        state.subOrganizations = sub_organizations;
        state.paginationData = { page, size, total, total_pages };
        state.isLoading = false;
      })
      .addCase(getAllSubOrganizations.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(resendInvitation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resendInvitation.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(resendInvitation.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(revokeInvitaion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(revokeInvitaion.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(revokeInvitaion.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(activateInvitation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(activateInvitation.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(activateInvitation.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteInvitation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteInvitation.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteInvitation.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getSuborganizationUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSuborganizationUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.subOrganizationUsers = action.payload;
      })
      .addCase(getSuborganizationUser.rejected, (state) => {
        state.isLoading = false;
        state.subOrganizationUsers = [];
      });
  },
});
export default suborganizationSlice.reducer;
