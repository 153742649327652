import { useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import logo from "assets/images/logos/header-logo-official.png";

import BasicLayout from "layouts/authentication/components/BasicLayout";

import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { registerSubAdminSchema } from "components/schema";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { createOrganization } from "store/Slices/authSlice";
import Loader from "components/Loader";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { RegisterSubAdmin } from "store/Slices/authSlice";
const initialValues = {
  fullname: "",
  password: "",
  cPassword: "",
};

function Basic() {
  const params = useParams();
  const url = params?.id;
  const token = url.split("token=")[1];
  const { loading } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: initialValues,
    validationSchema: registerSubAdminSchema,
    onSubmit: (values) => {
      const payload = {
        token,
        fullName: values.fullname,
        password: values.password,
      };
      dispatch(
        RegisterSubAdmin({
          payload,
          onSuccess: (res) => {},
        })
      )
        .unwrap()
        .then((resolved) => {
          resetForm();
        })
        .catch((rejected) => {
          resetForm();
          toast.error(rejected?.response?.data?.detail);
        });
    },
  });

  return (
    <BasicLayout image={bgImage}>
      {loading && <Loader />}
      <Card>
        <MDBox pt={2} px={3}>
          <MDBox mx={2} mt={1} p={2} mb={1} textAlign="center">
            <img src={logo} alt="Logo" />
          </MDBox>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Full Name"
                name="fullname"
                fullWidth
                value={values.fullname}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.fullname && touched.fullname ? (
                <MDTypography color="error" sx={{ fontSize: "13px" }}>
                  {<p>{errors.fullname}</p>}
                </MDTypography>
              ) : null}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                name="password"
                fullWidth
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.password && touched.password ? (
                <MDTypography color="error" sx={{ fontSize: "13px" }}>
                  {<p>{errors.password}</p>}
                </MDTypography>
              ) : null}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Confirm Password"
                name="cPassword"
                fullWidth
                value={values.cPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.cPassword && touched.cPassword ? (
                <MDTypography color="error" sx={{ fontSize: "13px" }}>
                  {<p>{errors.cPassword}</p>}
                </MDTypography>
              ) : null}
            </MDBox>
            <MDBox mt={2} mb={2}>
              <MDButton variant="gradient" color="info" type="submit" fullWidth>
                Register
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
