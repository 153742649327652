import { Box, Button, Card, Grid, Modal, Typography } from "@mui/material";
import React from "react";
const styleModal = {
  width: "90vw", // use a percentage width for responsiveness
  maxWidth: "500px", // set a max width to prevent it from getting too large
  margin: "auto", // center the modal
  padding: "20px", // add some padding
  boxShadow: 24,
  borderRadius: "8px",
};
export default function index({ modalOpen, modalToggle, onSubmit, loading }) {
  return (
    <Modal
      open={modalOpen}
      onClose={modalToggle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
        <Card sx={styleModal}>
          <Box mx={1} p={1} mt={1}>
            <Typography variant="h4" fontWeight="medium" color="textPrimary" align="center" mb={1}>
              Are you sure you want to delete?
            </Typography>
            <Box display="flex" justifyContent="center" mt={2}>
              <Button
                variant="contained"
                sx={{
                  color: "#fff",
                  bgcolor: "#4ebc7d",
                }}
                style={{ marginRight: 10 }}
                onClick={onSubmit}
                disabled={loading}
              >
                {loading ? "Deleting..." : "Yes"}
              </Button>
              <Button
                variant="contained"
                sx={{
                  color: "#fff",
                  bgcolor: "#ec4b48",
                }}
                onClick={modalToggle}
                disabled={loading}
              >
                No
              </Button>
            </Box>
          </Box>
        </Card>
      </Grid>
    </Modal>
  );
}
