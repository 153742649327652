import { Button, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import { featurePermissionsObject } from "context";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import jsPDF from "jspdf";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const UserStatsComponent = ({ featurePermissions }) => {
  const userStats = useSelector((state) => state.users);
  const [storePermissions, setStorePermissions] = useState(null);

  const labels = Object.keys(userStats?.monthlyAvgScoreVal);
  const scores = Object.values(userStats?.monthlyAvgScoreVal);

  const data = {
    labels,
    datasets: [
      {
        label: userStats?.monthlyAvgScoreDetail,
        data: scores,
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: true,
        tension: 0.3,
        borderWidth: 2,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        max: 10,
      },
    },
  };

  const formatTimeSpent = (minutes) => {
    const duration = moment.duration(minutes, "minutes");
    const hours = Math.floor(duration.asHours());
    const mins = duration.minutes();
    return `${hours}hr : ${mins} min`;
  };

  const handleDisabled = (val) => {
    if (storePermissions) {
      if (storePermissions.hasOwnProperty(val)) {
        return !storePermissions[val];
      }
      return null;
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();

    // Customer Info
    doc.setFontSize(18);
    doc.text("Customer Information", 14, 22);

    const customerInfo = userStats?.summaryReportVal.customer_info;
    doc.setFontSize(12);
    doc.text(`Name: ${customerInfo.name}`, 14, 32);
    doc.text(`Contact: ${customerInfo.contact}`, 14, 38);
    doc.text(`Employment Status: ${customerInfo.employment_status}`, 14, 44);
    doc.text(`Education: ${customerInfo.education}`, 14, 50);
    doc.text(`Address: ${customerInfo.address}`, 14, 56);
    doc.text(`LinkedIn Profile: ${customerInfo.linkedIn_profile}`, 14, 62);

    // Onboarding Info
    doc.setFontSize(18);
    doc.text("Onboarding Information", 14, 72);

    const onboardingData = userStats?.summaryReportVal.onboarding_info.map((info) => [
      info.question,
      info.answer,
    ]);
    doc.autoTable({
      startY: 78,
      head: [["Question", "Answer"]],
      body: onboardingData,
      theme: "striped",
      headStyles: { fillColor: [22, 160, 133] },
      columnStyles: {
        0: { cellWidth: 60 },
        1: { cellWidth: "auto" },
      },
      margin: { top: 10 },
      styles: {
        cellPadding: 3,
        fontSize: 10,
        overflow: "linebreak",
      },
    });

    // Training Sessions
    const finalY = doc.lastAutoTable.finalY + 10;
    doc.setFontSize(18);
    doc.text("Training Sessions", 14, finalY);

    const trainingSessions = userStats?.summaryReportVal.training_sessions;
    doc.setFontSize(12);
    doc.text(`Total Count: ${trainingSessions.total_count}`, 14, finalY + 10);
    doc.text(`Abandon Count: ${trainingSessions.abandon_count}`, 14, finalY + 16);
    doc.text(
      `Average Score: ${Number(trainingSessions?.average_score || 0).toFixed(2)}`,
      14,
      finalY + 22
    );

    // Simulation Sessions
    const simFinalY = finalY + 32;
    doc.setFontSize(18);
    doc.text("Simulation Sessions", 14, simFinalY);

    const simulationSessions = userStats?.summaryReportVal.simulation_sessions;
    doc.setFontSize(12);
    doc.text(`Total Count: ${simulationSessions?.total_count}`, 14, simFinalY + 10);
    doc.text(`Abandon Count: ${simulationSessions.abandon_count}`, 14, simFinalY + 16);
    doc.text(
      `Average Score: ${Number(simulationSessions?.average_score || 0).toFixed(2)}`,
      14,
      simFinalY + 22
    );

    doc.save("summary-report.pdf");
  };

  useEffect(() => {
    if (featurePermissions) {
      const data = featurePermissionsObject(featurePermissions);
      setStorePermissions(data);
    }
  }, []);

  const cardData = [
    {
      title: "Practice Rounds",
      count: userStats.userTrainingStats?.total_sessions,
      color: "dark",
      icon: "model_training",
    },
    {
      title: "Interview Simulations Taken",
      count: userStats.userSimulationStats?.total_sessions,
      icon: "leaderboard",
    },
    { title: "Resume's started", count: userStats.userResumes, color: "success", icon: "article" },
    {
      title: "Average Resumes Score",
      count:
        userStats.resumeAvgScore && typeof userStats.resumeAvgScore === "number"
          ? userStats.resumeAvgScore.toFixed(2)
          : userStats.resumeAvgScore,
      color: "warning",
      icon: "article",
    },
    {
      title: "Resumes Created",
      count: userStats.compltedResumeCount,
      color: "info",
      icon: "person",
    },
    {
      title: "Completed Practice Rounds",
      color: "success",
      icon: "model_training",
      displayIcon: userStats?.trainingStatus ? "check" : "close",
      iconReason: "No Completed Practice round found",
      session_details: userStats?.roundsCompletionData?.session_details,
    },
    {
      title: "Completed Interview Simulations",
      color: "warning",
      icon: "leaderboard",
      displayIcon: userStats?.simulationStatus ? "check" : "close",
      iconReason: "No Completed Interview simulation found",
      categories: userStats?.simulationData?.categories,
    },
    {
      title: "Average practice rounds + simulation score",
      color: "primary",
      icon: "article",
      count: userStats?.averageScore,
    },
    {
      title: "Feedback and Satisfaction",
      color: "secondary",
      icon: "grading",
      unSatisfactoryReason: "Last 3 Sessions feedback Scores",
      count: userStats?.satisfaction,
    },
    {
      title: "Career Readiness",
      color: "primary",
      icon: "update",
      count: userStats?.careerReadinessVal,
    },
    {
      title: "Login Frequency",
      color: "dark",
      icon: "login",
      count: userStats?.loginFrequencyVal,
    },
    {
      title: "Time Spent On Platform",
      icon: "timelapse",
      count: formatTimeSpent(userStats?.timeSpentVal),
    },
    {
      title: "Average Interview Simulation Score",
      icon: "scoreboard",
      color: "success",
      count: Number(userStats?.simulationScorePerUserVal).toFixed(2),
    },
    {
      title: "Average Practice Rounds Score",
      icon: "scoreboard",
      color: "success",
      count: Number(userStats?.pRoundScorePerUserVal).toFixed(2),
    },
    {
      title: "Top Interview Simulation Score",
      icon: "scoreboard",
      color: "success",
      count: Number(userStats?.simulationSessionTopScoreVal).toFixed(2),
    },
    {
      title: "Top Practice Rounds Score",
      icon: "scoreboard",
      color: "success",
      count: Number(userStats?.pRoundSessionTopScoreVal).toFixed(2),
    },
    {
      title: "Incomplete Interview Simulation",
      icon: "pendingActions",
      color: "warning",
      count:
        userStats?.incompletionRatesVal?.incomplete_simulations ||
        0 / userStats?.incompletionRatesVal?.total_simulations ||
        0,
    },
    {
      title: "Incomplete Practice Rounds",
      icon: "pendingActions",
      color: "warning",
      count:
        userStats?.incompletionRatesVal?.incomplete_trainings ||
        0 / userStats?.incompletionRatesVal?.total_trainings ||
        0,
    },
    {
      title: "IQ Search Count",
      icon: "search",
      color: "primary",
      count: userStats?.iqSearchCountVal,
      toltip: "Number of job Search that user have searched",
    },
    {
      title: "IQ Alert Count",
      icon: "model_training",
      color: "dark",
      count: userStats?.iqAlertCountVal,
      toltip: "Number of job alerts sent to the user",
    },
    {
      title: "IQ Applied Jobs",
      icon: "article",
      color: "warning",
      toltip: "List of jobs that users have applied",
      displayIcon: userStats?.iqAppliedCountVal?.applied_jobs.length > 0 ? "check" : "close",
      session_details: userStats?.iqAppliedCountVal?.applied_jobs,
      isAppliedJobCount: true,
    },
  ];

  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid
              display="flex"
              justifyContent="end"
              item
              xs={12}
              sx={{ paddingTop: "0px !important" }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{ color: "#fff", marginBottom: "8px" }}
                onClick={generatePDF}
                disabled={handleDisabled("create")}
              >
                Download Summary Report
              </Button>
            </Grid>
            {cardData?.map((card, index) => (
              <Grid item xs={12} md={6} lg={3} key={index}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color={card?.color}
                    icon={card?.icon}
                    title={card?.title}
                    count={card?.count}
                    displayIcon={card?.displayIcon}
                    iconReason={card?.iconReason}
                    categories={card?.categories}
                    unSatisfactoryReason={card?.unSatisfactoryReason}
                    session_details={card?.session_details}
                    isAppliedJobCount={card?.isAppliedJobCount}
                    toltip={card?.toltip}
                  />
                </MDBox>
              </Grid>
            ))}
            <Grid item lg={12}>
              <MDBox mb={1.5}>
                <Line data={data} options={options} />
              </MDBox>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default UserStatsComponent;
