import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";
const chatRepo = RepositoryFactory.get("chat");
export const getUserId = createAsyncThunk("chat/getUserId", async (_, { rejectWithValue }) => {
  try {
    const { data } = await chatRepo.getUserId();
    return data;
  } catch (err) {
    toast.error(err.response?.data?.detail);
    return rejectWithValue(err.message);
  }
});
export const sendMessageAction = createAsyncThunk(
  "chat/sendMessage",
  async ({ formData, onSuccess }, { rejectWithValue }) => {
    try {
      const response = await chatRepo.sendMessage(formData);
      if (response.status === 200) {
        onSuccess({ response: response.data, status: true });
        return response.data;
      }
    } catch (err) {
      onSuccess({ response: "", status: false });
      return rejectWithValue(err);
    }
  }
);
export const startChatAction = createAsyncThunk(
  "chat/createChat",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await chatRepo.startChat(payload);
      if (data) {
        onSuccess({ status: true, data: data });
        return data;
      }
    } catch (err) {
      // onSuccess({ status: false, data: "" });
      return rejectWithValue(err);
    }
  }
);
export const getChat = createAsyncThunk("chat/getChats", async (onSuccess, { rejectWithValue }) => {
  try {
    const res = await chatRepo.getAllChats();
    let data = res?.data;
    if (data) {
      onSuccess();
      return data;
    }
  } catch (err) {
    onSuccess();
    toast.error("something went wrong!");
    return rejectWithValue(err);
  }
});
export const updateActiveConversation = createAsyncThunk(
  "chat/updateActiveConversation",
  async (activeConversations, { getState, dispatch }) => {
    const { connectedChats } = getState()?.chats;
    let updatedConnectedChats = connectedChats?.map((msg) => ({ ...msg }));
    const index = updatedConnectedChats.findIndex((chat) => chat.id === activeConversations?.id);
    if (index !== -1) {
      updatedConnectedChats[index] = activeConversations;
    }
    dispatch(setConnectedChats(updatedConnectedChats));
    return activeConversations;
  }
);
export const updateConversations = createAsyncThunk(
  "chat/updateConversations",
  async (updatedConversation, { getState, dispatch, rejectWithValue }) => {
    try {
      const { connectedChats, activeConversation } = getState()?.chats;
      let updatedConnectedChats = connectedChats?.map((msg) => ({ ...msg }));
      updatedConnectedChats = updatedConnectedChats.filter(
        (msg) => msg.id !== updatedConversation?.id
      );
      let modifiedUpdatedConversation = updatedConversation;
      if (activeConversation?.id === updatedConversation?.id) {
        modifiedUpdatedConversation = {
          ...updatedConversation,
          unread_messages: activeConversation?.unread_messages,
        };
      }
      updatedConnectedChats.unshift(modifiedUpdatedConversation);
      dispatch(setConnectedChats(updatedConnectedChats));
      return updatedConnectedChats;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const modifyConversations = createAsyncThunk(
  "chat/modifyConversations",
  async (updatedConversation, { getState, dispatch, rejectWithValue }) => {
    try {
      const { connectedChats } = getState()?.chats;
      let updatedConnectedChats = connectedChats?.map((msg) => ({ ...msg })) || [];
      const conversationExists = updatedConnectedChats.some(
        (chat) => chat.id === updatedConversation.id
      );
      if (!conversationExists) {
        updatedConnectedChats.unshift(updatedConversation);
      }
      dispatch(setConnectedChats(updatedConnectedChats));
      return updatedConnectedChats;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getChatMessages = createAsyncThunk(
  "chat/getChatMessages",
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      const res = await chatRepo.getSingleChat(id);
      let data = res?.data;
      if (data && data.messages) {
        // Temporary array to hold the grouped messages
        let tempMessages = [];
        // Group messages by date
        data.messages.forEach((message) => {
          const createdAt = moment(message.created_at).format("DD/MM/YYYY");

          const existingGroup = tempMessages.find((group) => group.date === createdAt);
          if (existingGroup) {
            existingGroup.groupedMessages.push(message);
          } else {
            tempMessages.push({
              date: createdAt,
              groupedMessages: [message],
            });
          }
        });
        onSuccess();
        return tempMessages; // Returning grouped messages
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateChatMessages = createAsyncThunk(
  "chat/updateChatMessages",
  async (lastMessage, { getState, rejectWithValue, dispatch }) => {
    try {
      const { chatMessages, activeConversation } = getState()?.chats;
      if (lastMessage.chat_id !== activeConversation.id) return;
      await chatRepo.unreadChat(lastMessage.chat_id);
      const lastMessageDate = moment(lastMessage.created_at).format("DD/MM/YYYY");
      let updatedChatMessages = chatMessages.map((msg) => ({ ...msg }));
      let existingDateObject = updatedChatMessages.find(({ date }) => date === lastMessageDate);
      if (existingDateObject) {
        const messageExists = existingDateObject.groupedMessages.some(
          ({ id }) => id === lastMessage.id
        );
        if (!messageExists) {
          existingDateObject.groupedMessages = [...existingDateObject.groupedMessages, lastMessage];
        }
      } else {
        updatedChatMessages = [
          ...updatedChatMessages,
          {
            date: lastMessageDate,
            groupedMessages: [lastMessage],
          },
        ];
      }
      dispatch(setChatMessages(updatedChatMessages));
      return updatedChatMessages;
    } catch (err) {
      return rejectWithValue(err.message || "An error occurred");
    }
  }
);
const initialState = {
  loading: false,
  singleChatLoading: false,
  connectedChats: [],
  chatMessages: null,
  activeConversation: null,
  userId: "",
};

const ChatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setChatMessages(state, action) {
      console.log("action: ", action);
      state.chatMessages = action.payload;
    },
    setConnectedChats(state, action) {
      state.connectedChats = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserId.fulfilled, (state, action) => {
        state.loading = false;
        state.userId = action.payload;
      })
      .addCase(getUserId.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getChat.pending, (state) => {
        state.loading = true;
      })
      .addCase(getChat.fulfilled, (state, action) => {
        state.loading = false;
        const sortedChats = [...action?.payload]?.sort((a, b) => {
          return new Date(b?.last_message?.created_at) - new Date(a?.last_message?.created_at);
        });
        state.connectedChats = sortedChats;
        state.chatMessages = null;
      })
      .addCase(getChat.rejected, (state, action) => {
        state.loading = false;
        state.connectedChats = [];
      })
      .addCase(getChatMessages.pending, (state) => {
        state.singleChatLoading = true;
        state.chatMessages = null;
      })
      .addCase(getChatMessages.fulfilled, (state, action) => {
        state.singleChatLoading = false;
        state.chatMessages = action.payload;
      })
      .addCase(getChatMessages.rejected, (state, action) => {
        state.singleChatLoading = false;
      })
      .addCase(updateActiveConversation.fulfilled, (state, action) => {
        state.activeConversation = action.payload;
      });
  },
});
export const { setChatMessages, setConnectedChats } = ChatSlice.actions;
export default ChatSlice.reducer;
