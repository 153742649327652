import { Link, useNavigate } from "react-router-dom";
import { Box, Button, Grid, Modal, useMediaQuery } from "@mui/material";
// import YoutubeEmbed from "../youtubeEmbed/YoutubeEmbed";
import logo from "assets/images/logos/header-logo-official.png";
import logo2 from "assets/images/logos/header-logo-official.png";
import halfLogo from "assets/images/logo.jpeg";
// import YoutubeEmbed from "components/YoutubeEmbed/YoutubeEmbed";

export const OnboardingLayout = ({
  children,
  activeStep,
  handleNext,
  goBack,
  answer,
  submitLater,
  //   modal,
}) => {
  const navigate = useNavigate();
  // console.log("===========activeStep=============", activeStep);
  // console.log("===========children length=============", children.length);
  // console.log("===========answer=============", answer);
  // // console.log("===========answer length=============", answer.length);
  const isSmallScreen = useMediaQuery("(max-width:500px)");
  const isMediumScreen = useMediaQuery("(max-width:768px)");

  const width = isSmallScreen ? "40px" : isMediumScreen ? "70px" : "120px";

  return (
    <Box
      sx={{
        minHeight: "100vh",
        height: "auto",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Grid container>
        <Grid item lg>
          <Box sx={{ height: "100%" }}>
            <Box
              className="header"
              sx={{
                backgroundColor: "transparent",
                padding: "16px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Link to="/">
                  <img src={logo} alt="Logo" />
                </Link>
              </Box>
            </Box>

            <Box
              className="content-container"
              sx={{
                padding: "48px",
                height: "calc(100% - 64px)",
                overflowY: "auto",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 4,
                }}
              >
                {Array(children.length)
                  .fill(1)
                  .map((_, index) => (
                    <Box
                      key={index}
                      className={`progess-line ${activeStep >= index + 1 && "completed"}`}
                      sx={{
                        height: "5px",
                        borderRadius: "25px",
                        width: width,
                        backgroundColor: activeStep >= index + 1 ? "green" : "#e4e4e4",
                        ml: 2,
                      }}
                    />
                  ))}
              </Box>
              {children}
              <Box sx={{ mt: 4 }}>
                {activeStep > 1 && (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      mr: 2,
                      color: "#fff",
                      borderRadius: "20px",
                      padding: "13px 30px",
                      fontSize: "14px",
                    }}
                    onClick={goBack}
                  >
                    Back
                  </Button>
                )}
                {activeStep <= children.length && (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      mr: 2,
                      color: "#fff",
                      borderRadius: "20px",
                      padding: "13px 30px",
                      fontSize: "14px",
                    }}
                    onClick={() => handleNext(activeStep + 1)}
                  >
                    Next
                  </Button>
                )}
                {activeStep <= children.length && activeStep >= 1 && (
                  <Button className="ic-btn chip-btn" onClick={() => submitLater(activeStep)}>
                    Save and do later
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          lg
          sx={{
            display: { xs: "none", lg: "flex" },
            alignItems: "center",
            justifyContent: "center",
            marginTop: "0",
            borderRadius: "50px",
            backgroundImage:
              "url(http://localhost:3000/static/media/ic-banner.557f8f42838fde60c453.jpg)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            overflow: "hidden",
          }}
        >
          <img
            src={halfLogo}
            className="img-fluid"
            style={{ width: "600px", borderRadius: "50px" }}
            alt="."
          />
        </Grid>
      </Grid>

      {/* <Modal
        open={modal}
        onClose={() => navigate("/")}
        aria-labelledby="exampleModalLabel"
        aria-hidden="false"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "white",
        }}
      >
        <Box
          className="modal-content"
          sx={{
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: 24,
            p: 4,
            maxWidth: "600px",
            width: "100%",
          }}
        >
          <Box
            className="header"
            // sx={{
            //   backgroundColor: "transparent",
            //   padding: "16px",
            // }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <button
                type="button"
                onClick={() => navigate("/")}
                sx={{
                  background: "#fff",
                  border: "none",
                  padding: "0",
                }}
              >
                <img src={logo2} alt="Logo" />
              </button>
            </Box>
          </Box>
          <Box className="video-container" sx={{ mt: 2 }}>
            <YoutubeEmbed embedId="J_S34tZd9Ao" />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Button type="button" className="ic-btn chip-btn" onClick={() => navigate("/home")}>
              Skip
            </Button>
          </Box>
        </Box>
      </Modal> */}
    </Box>
  );
};
