import Repository from "./Repository";
const POST_INVITE_ADMIN = "/organization/sub_admin/invite";
const GET_ALL_ADMINS = "/organization/sub_admin/get_all";
const REVOKED = "/organization/sub_admin/revoke";
const ACTIVE_REVOKED = "/organization/sub_admin/active_revoked_invitation";
const DELETE_REVOKED_ADMIN = "/organization/sub_admin/invite/delete";
const RESEND_INVITATION = "/organization/sub_admin/resend";
const UPDATE_ADMIN = "/organization/sub_admin/role_update";

const adminRepository = {
  post_Invite_Admin(payload) {
    return Repository.post(`${POST_INVITE_ADMIN}`, payload);
  },
  updateSubAdmin(payload) {
    return Repository.put(`${UPDATE_ADMIN}`, payload);
  },
  get_All_Admins(payload) {
    return Repository.get(`${GET_ALL_ADMINS}?${payload}`);
  },
  put_Revoked(payload) {
    return Repository.put(`${REVOKED}`, payload);
  },
  put_Active_Revoked(payload) {
    return Repository.put(`${ACTIVE_REVOKED}`, payload);
  },
  deleteRevokedAdmin(payload) {
    const emailPayload = {
      email: payload,
    };
    return Repository.post(`${DELETE_REVOKED_ADMIN}`, emailPayload);
  },
  Resend_Invitation(payload) {
    return Repository.post(`${RESEND_INVITATION}`, payload);
  },
};

export default adminRepository;
