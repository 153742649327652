import React from "react";

const TextAreaComponent = ({ value, onChange, placeholder, name, rows, required }) => {
  return (
    <textarea
      className="custom-textarea"
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      rows={rows}
      required={required}
    />
  );
};

export default TextAreaComponent;
