import { Box, TextField, TextareaAutosize } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React, { useState } from "react";
import Select from "react-select";
import DropDowns from "./DropDowns";
import { Label } from "@mui/icons-material";
import { createByChatGpt } from "store/Slices/questionsSlice";
import { useDispatch, useSelector } from "react-redux";
import { getQuestions } from "store/Slices/questionsSlice";
import TextFields from "./TextFields";
const bodyStyle = {
  height: "auto",
};
export default function ChatGptQuestions({
  chatgptQuestions,
  setChatgptQuestions,
  rolesDetails,
  categoriesDetails,
  setAddModalOpen,
  debouncedSearch,
  questionsType,
  page,
}) {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.questions);
  const [closeModal, setCloseModal] = useState();
  const handleQuestionTextChange = (event) => {
    const updatedQuestions = { ...chatgptQuestions, questionNo: event.target.value };
    setChatgptQuestions(updatedQuestions);
  };

  const handleTextFields = (index, event) => {
    const { name, value } = event.target;
    setChatgptQuestions({ ...chatgptQuestions, [name]: value });
  };

  const handleLocationChange = (name, index, value) => {
    const updatedQuestions = { ...chatgptQuestions, [name]: value };
    setChatgptQuestions(updatedQuestions);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let payload = {};
    if (chatgptQuestions?.role_category_id)
      payload.role_category_id = chatgptQuestions?.role_category_id;
    if (chatgptQuestions?.role_subcategory_id)
      payload.role_subcategory_id = chatgptQuestions?.role_subcategory_id;
    if (chatgptQuestions?.question_type_id)
      payload.question_type_id = chatgptQuestions?.question_type_id;
    if (chatgptQuestions?.questionNo) {
      payload.no_of_questions = chatgptQuestions?.questionNo;
    } else {
      payload.no_of_questions = "1";
    }
    if (chatgptQuestions?.specific_role) payload.specific_role = chatgptQuestions?.specific_role;
    if (chatgptQuestions?.location) payload.location = chatgptQuestions?.location;
    if (chatgptQuestions?.company_name) payload.company_name = chatgptQuestions?.company_name;
    dispatch(
      createByChatGpt({
        payload,
        onSuccess: () => {
          if (closeModal) {
            setChatgptQuestions({
              question_type_id: "",
              role_category_id: "",
              role_subcategory_id: null,
              questionNo: "",
              roleId: "",
              specific_role: "",
              location: "",
              company_name: "",
            });
          } else {
            setChatgptQuestions({
              ...chatgptQuestions,
              questionNo: "",
            });
          }

          dispatch(getQuestions({ page: page, search: debouncedSearch }));
        },
      })
    );
    if (closeModal) {
      setAddModalOpen(false);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Box sx={bodyStyle}>
        <Box mb={2}>
          <DropDowns
            rolesDetails={rolesDetails}
            question={chatgptQuestions}
            categoriesDetails={categoriesDetails}
            Questions={chatgptQuestions}
            setQuestions={setChatgptQuestions}
            index={""}
            questionsType={questionsType}
            isDisabled={loading}
          />
          <TextFields
            question={chatgptQuestions}
            index={""}
            handleTextFields={handleTextFields}
            handleLocationChange={handleLocationChange}
            isDisabled={loading}
          />
          <TextField
            type="number"
            inputProps={{ min: 1 }}
            placeholder="Example:500"
            value={chatgptQuestions.questionNo}
            onChange={(event) => handleQuestionTextChange(event)}
            style={{ width: "100%", marginTop: "12px" }}
            required
            disabled={loading}
          />
        </Box>
      </Box>
      <MDBox mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
        <MDButton
          variant="gradient"
          color="info"
          onClick={() => setAddModalOpen(false)}
          disabled={loading}
        >
          Cancel
        </MDButton>
        <MDButton
          variant="gradient"
          color="info"
          type="submit"
          disabled={loading}
          onClick={() => setCloseModal(true)}
        >
          Submit and Close
        </MDButton>
        <MDButton variant="gradient" color="info" type="submit" disabled={loading}>
          Submit and Continue
        </MDButton>
      </MDBox>
    </form>
  );
}
