import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";
const questionRepo = RepositoryFactory.get("questions");

export const addSimpleQuestions = createAsyncThunk(
  "question/addSimpleQuestions",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const response = await questionRepo.addSimpleQuestions(payload);
      if (response.status === 200) {
        onSuccess();
        toast.success("Questions added successfully");
        return response.data;
      }
    } catch (err) {
      toast.error(err.response.data.detail);
      return rejectWithValue(err);
    }
  }
);
export const importQuestion = createAsyncThunk(
  "question/importQuestion",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const response = await questionRepo.importQuestions(payload);
      if (response.status === 200) {
        onSuccess();
        toast.success("Questions imported successfully");
        return response.data;
      }
    } catch (err) {
      toast.error(err.response.data.detail);
      return rejectWithValue(err);
    }
  }
);
export const createByChatGpt = createAsyncThunk(
  "question/createByChatGpt",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const response = await questionRepo.createByChatGpt(payload);
      if (response.status === 200) {
        onSuccess();
        toast.success("Questions added successfully");
        return response.data;
      }
    } catch (err) {
      toast.error(err.response.data.detail);
      return rejectWithValue(err);
    }
  }
);
export const getQuestions = createAsyncThunk(
  "question/getQuestions",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await questionRepo.getQuestions(payload);
      if (data.status_code === 200) {
        return data.data;
      }
    } catch (err) {
      toast.error(err.response.data.detail);
      return rejectWithValue(err);
    }
  }
);
export const deleteQuestion = createAsyncThunk(
  "question/deleteQuestion",
  async ({ questionId, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await questionRepo.deleteQuestion(questionId);
      if (data.status_code === 200) {
        toast.success(data.detail);
        onSuccess();
        return data;
      }
    } catch (err) {
      toast.error(err.response.data.detail);
      return rejectWithValue(err);
    }
  }
);
export const deleteQuestionsBulk = createAsyncThunk(
  "question/deleteQuestionsBulk",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await questionRepo.deleteQuestionsBulk(payload);
      if (data.status_code === 200) {
        toast.success(data.detail);
        onSuccess();
        return data;
      }
    } catch (err) {
      toast.error(err.response.data.detail);
      return rejectWithValue(err);
    }
  }
);
export const updateQuestion = createAsyncThunk(
  "question/updateQuestion",
  async ({ questionId, payload, onSuccess }, { rejectWithValue }) => {
    try {
      const response = await questionRepo.updateQuestion(questionId, payload);
      if (response.status === 200) {
        onSuccess();
        toast.success("Question Updated Successfully");
        return data;
      }
    } catch (err) {
      toast.error(err.response.data.detail);
      return rejectWithValue(err);
    }
  }
);
const initialState = {
  loading: false,
  questions: [],
  total: 0,
  page: 0,
  totalPages: 0,
};

const questionsSlice = createSlice({
  name: "question",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //Add Simple Questions
      .addCase(addSimpleQuestions.pending, (state) => {
        state.loading = true;
      })
      .addCase(addSimpleQuestions.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addSimpleQuestions.rejected, (state, action) => {
        state.loading = false;
      })
      //Import Questions
      .addCase(importQuestion.pending, (state) => {
        state.loading = true;
      })
      .addCase(importQuestion.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(importQuestion.rejected, (state, action) => {
        state.loading = false;
      })
      //Create By ChatGpt
      .addCase(createByChatGpt.pending, (state) => {
        state.loading = true;
      })
      .addCase(createByChatGpt.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createByChatGpt.rejected, (state, action) => {
        state.loading = false;
      })
      //Get Questions
      .addCase(getQuestions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQuestions.fulfilled, (state, action) => {
        state.loading = false;
        state.questions = action.payload.questions;
        state.total = action.payload.total;
        state.page = action.payload.page;
        state.totalPages = action.payload.total_pages;
      })
      .addCase(getQuestions.rejected, (state, action) => {
        state.loading = false;
      })
      //Delete Question
      .addCase(deleteQuestion.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteQuestion.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteQuestion.rejected, (state, action) => {
        state.loading = false;
      })
      //Delete Questions Bulk
      .addCase(deleteQuestionsBulk.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteQuestionsBulk.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteQuestionsBulk.rejected, (state, action) => {
        state.loading = false;
      })
      //Update Question
      .addCase(updateQuestion.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateQuestion.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateQuestion.rejected, (state, action) => {
        state.loading = false;
      });
  },
});
export default questionsSlice.reducer;
