import { useState, useEffect } from "react";

import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import UserImg from "../../../../assets/images/logos/user.png";

import breakpoints from "assets/theme/base/breakpoints";
import { useSelector } from "react-redux";

function Header({ children, setactiveState }) {
  const { orgTheme } = useSelector((state) => state.oraganizationTheme);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    setactiveState(newValue);
    setTabValue(newValue);
  };

  return (
    <MDBox position="relative" mb={5}>
      <Card
        sx={{
          position: "relative",
          mt: 4,
          mx: 1,
          py: 2,
          px: 2,
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;",
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar
              src={orgTheme?.file_url || UserImg}
              alt="profile-image"
              sx={{
                height: "100px",
                width: "100px",
              }}
              shadow="sm"
            />
          </Grid>

          <Grid item xs={12} md={6} lg={5} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                <Tab
                  label="General Settings"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      group
                    </Icon>
                  }
                />
                <Tab
                  label="Update Password"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      password
                    </Icon>
                  }
                />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

Header.defaultProps = {
  children: "",
};

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
