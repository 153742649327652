// import Repository from "./Repository";
// const GET_ROLES_CATEGORIES = "/organization/job-role-categories";

// const roleCategoryRepository = {
//   getAllRoleCategories() {
//     return Repository.get(`${GET_ROLES_CATEGORIES}`);
//   },
// };

// export default roleCategoryRepository;
import Repository from "./Repository";
const ADD_ROLE_CATEGORY = "/organization/job-role-category";
const GET_ROLES_CATEGORIES = "/organization/job-role-categories";
const DELETE_ROLE_CATEGORY = "/organization/job-role-category";
const BEFORE_DELETE_ROLE_CATEGORY = "/organization/job-role-category";
const UPDATE_ROLE_CATEGORY = "/organization/job-role-category";
const GET_ALL_SUB_CATEGORIES = "organization/job-role-subcategories";

const roleCategoryRepository = {
  addRoleCategory(payload) {
    return Repository.post(`${ADD_ROLE_CATEGORY}`, payload);
  },
  getAllRoleCategories() {
    return Repository.get(`${GET_ROLES_CATEGORIES}`);
  },
  deleteRoleCategory(categoryId) {
    return Repository.delete(`${DELETE_ROLE_CATEGORY}?job_role_category_id=${categoryId}`);
  },
  beforeDeleteRoleCategory(categoryId) {
    return Repository.get(`${BEFORE_DELETE_ROLE_CATEGORY}/${categoryId}/has-sessions`);
  },
  updateRoleCategory(payload) {
    return Repository.put(
      `${UPDATE_ROLE_CATEGORY}?job_role_category_id=${payload.categoryId}`,
      payload.form
    );
  },
  getDeveloperRoleSubCategories() {
    return Repository.get(`${GET_ALL_SUB_CATEGORIES}`);
  },
};

export default roleCategoryRepository;
