import Loader from "components/Loader";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  careerReadiness,
  loginFrequency,
  simulationScorePerUser,
  simulationSessionTopScore,
  incompletionRates,
  pRoundSessionTopScore,
  pRoundScorePerUser,
  timeSpent,
  summaryReport,
  monthlyAvgScore,
  getUserFeedbackSatisfaction,
  getUserAvgScore,
  getUserSimultionStatus,
  userResumeStats,
  getUserCompletedResumes,
  getUserTrainingStatus,
  getUserSimulationStats,
  getUserTrainingStats,
} from "store/Slices/usersSlice";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "jspdf-autotable";
import UserStatsComponent from "components/UserStatsComponent";
import { IqSearchCount } from "store/Slices/usersSlice";
import { IqAlertCount } from "store/Slices/usersSlice";
import { IqAppliedJobCount } from "store/Slices/usersSlice";

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const UserStats = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const spiltID = id?.split("_")?.[0];

  const { loading } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(getUserTrainingStats(spiltID));
    dispatch(getUserSimulationStats(spiltID));
    dispatch(getUserTrainingStatus(spiltID));
    dispatch(getUserSimultionStatus(spiltID));
    dispatch(userResumeStats(spiltID));
    dispatch(getUserCompletedResumes(spiltID));
    dispatch(getUserAvgScore(spiltID));
    dispatch(getUserFeedbackSatisfaction(spiltID));

    dispatch(monthlyAvgScore(spiltID));
    dispatch(careerReadiness(spiltID));
    dispatch(summaryReport(spiltID));
    dispatch(loginFrequency(spiltID));
    dispatch(timeSpent(spiltID));
    dispatch(simulationScorePerUser(spiltID));
    dispatch(pRoundScorePerUser(spiltID));
    dispatch(simulationSessionTopScore(spiltID));
    dispatch(pRoundSessionTopScore(spiltID));
    dispatch(incompletionRates(spiltID));
    dispatch(IqSearchCount(spiltID));
    dispatch(IqAlertCount(spiltID));
    dispatch(IqAppliedJobCount(spiltID));
  }, []);

  return (
    <>
      {loading && <Loader />}
      <UserStatsComponent />
    </>
  );
};

export default UserStats;
