import { Card } from "@mui/material";
import Modal from "@mui/material/Modal";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useDispatch } from "react-redux";
import { getSubscription } from "store/Slices/authSlice";
import { cancelSubscription } from "store/Slices/subscriptionSlice";

const CancelSubscriptionModal = ({ modal, handleClose }) => {
  const dispatch = useDispatch();
  const handleSubmit = () => {
    dispatch(cancelSubscription())
      .unwrap()
      .then((res) => {
        dispatch(getSubscription());
      });
    handleClose();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Modal
        open={modal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style}>
          <MDBox>
            <MDTypography variant="h6">Cancel Subscription</MDTypography>
          </MDBox>
          <MDBox mx={1} p={1} mt={1}>
            <MDTypography variant="p" mt={1} mb={3}>
              Canceling your subscription will transition your current plan to a trial plan, which
              allows up to 5 users in your organization. Any additional users will have their access
              revoked. Are you sure you want to proceed with canceling your subscription?
            </MDTypography>
            <MDBox mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
              <MDButton variant="gradient" color="info" onClick={handleClose}>
                Cancel
              </MDButton>
              <MDButton variant="gradient" color="error" onClick={handleSubmit}>
                Submit
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </Modal>
    </div>
  );
};

export default CancelSubscriptionModal;
