import {
  Box,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Multiselect from "multiselect-react-dropdown";
import { useDispatch, useSelector } from "react-redux";
import { getSingleTask } from "store/Slices/TaskSlice";
import { AddAssignUser } from "store/Slices/TaskSlice";
import { toast } from "react-toastify";
import { removeAssignUser } from "store/Slices/TaskSlice";
import Loader from "components/Loader";
import { getAllTasks } from "store/Slices/TaskSlice";

const AssignTaskModal = ({ open, onClose, storeTaskId, storeActivePagination }) => {
  const dispatch = useDispatch();
  const { getSingleTaskData, loading } = useSelector((state) => state.task);
  const { joinedUsers } = useSelector((state) => state.users);
  const [addMode, setAddMode] = useState(false);
  const [assignObject, setAssignObject] = useState([]);
  const [custumerId, setCustumerId] = useState(null);
  const styleModal = {
    width: "90vw",
    maxWidth: "500px",
    margin: "auto",
    padding: "20px",
    boxShadow: 24,
    borderRadius: "8px",
  };

  const handleDeleteAssignUser = (customer_id) => {
    if (getSingleTaskData?.assigned_user_list?.length <= 1) {
      toast.error("Can't remove last user");
      return;
    }
    const payload = {
      customer_id,
      task_id: storeTaskId,
    };
    dispatch(
      removeAssignUser({
        payload,
        onSuccess: () => {
          dispatch(
            getSingleTask({
              payload: storeTaskId,
              onSuccess: (res) => {
                dispatch(getAllTasks({ payload: storeActivePagination || 1 }));
                toast.success("User Removed Successfully");
              },
            })
          );
        },
      })
    );
  };

  const handleCancel = () => {
    if (addMode) {
      setAddMode(!addMode);
    } else {
      onClose();
    }
  };

  const handleSubmitModal = () => {
    if (addMode) {
      const payload = {
        customer_id: custumerId,
        task_id: storeTaskId,
      };
      if (assignObject?.length < 1) {
        toast.error("No More Users Available");
        return;
      }
      if (!custumerId) {
        toast.error("Please Select User");
        return;
      }
      dispatch(
        AddAssignUser({
          payload,
          onSuccess: () => {
            dispatch(
              getSingleTask({
                payload: storeTaskId,
                onSuccess: (res) => {
                  setCustumerId(null);
                  setAddMode(!addMode);
                  dispatch(getAllTasks({ payload: storeActivePagination || 1 }));
                },
              })
            );
          },
        })
      );
    } else {
      setAddMode(!addMode);
    }
  };

  useEffect(() => {
    const assigned_user_listIds = getSingleTaskData?.assigned_user_list?.map(
      (user) => user.customer_id
    );
    const unmatchedObjs = joinedUsers?.filter(
      (item) => assigned_user_listIds && !assigned_user_listIds.includes(item.customer_id)
    );
    console.log(unmatchedObjs, "unmatchedObjs");
    setAssignObject(unmatchedObjs);
  }, [getSingleTaskData]);

  useEffect(() => {
    setAddMode(false);
    setCustumerId(null);
  }, [onClose]);
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
        {loading && <Loader />}
        <Card sx={styleModal}>
          <Box
            variant="gradient"
            bgcolor="info.main"
            borderRadius="12px"
            boxShadow={3}
            mx={2}
            p={2}
            mb={1}
            textAlign="center"
          >
            <Typography variant="h4" fontWeight="medium" color="white.main" mt={1} mb={1}>
              Assign Users
            </Typography>
          </Box>
          <Box mx={1} p={1} mt={1}>
            {addMode ? (
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Assign To:</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Assign To"
                    name="assignTo"
                    value={custumerId}
                    onChange={(e) => setCustumerId(e.target.value)}
                    required
                  >
                    {assignObject.length === 0 ? (
                      <MenuItem disabled value="">
                        <Typography>No users available</Typography>
                      </MenuItem>
                    ) : (
                      assignObject.map((option, index) => (
                        <MenuItem key={index} value={option.customer_id}>
                          {option.customer_name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Box>
            ) : (
              <List
                sx={{
                  width: "100%",
                  borderRadius: "12px",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                {getSingleTaskData?.assigned_user_list?.map((item, index) => (
                  <ListItem
                    key={index + 100}
                    sx={{
                      p: 2,
                      display: "flex",
                      justifyContent: "space-between",
                      borderRadius: "12px",
                      mb: 2,
                      backgroundColor: "#f7f7f7",
                    }}
                  >
                    <Box>
                      <Typography variant="h6" component="div" sx={{ color: "white" }}>
                        <span style={{ fontWeight: "bold", marginLeft: 8, fontSize: "1.3rem" }}>
                          {item?.customer_name}
                        </span>
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <IconButton
                        color="inherit"
                        onClick={() => handleDeleteAssignUser(item.customer_id)}
                      >
                        <DeleteIcon color="#ef5350" />
                      </IconButton>
                    </Box>
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
          <Box mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
            <MDButton variant="gradient" color="info" onClick={handleCancel}>
              {addMode ? "Back" : "Cancel"}
            </MDButton>
            <MDButton variant="gradient" color="info" onClick={handleSubmitModal}>
              {addMode ? "Submit" : "Add"}
            </MDButton>
          </Box>
        </Card>
      </Grid>
    </Modal>
  );
};

export default AssignTaskModal;
