import {
  Box,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { OnboardingLayout } from "components/OnBording/layout";
import Terms2 from "components/OnBording/term2";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import uplaodIcon from "assets/images/document-upload.png";
import { AddBoarding } from "store/Slices/BoardingSlice";
import { useDispatch, useSelector } from "react-redux";
import { getBoarding } from "store/Slices/BoardingSlice";
import { setOnboardStep } from "store/Slices/BoardingSlice";
import { updateOnboarding } from "store/Slices/BoardingSlice";
import Loader from "components/Loader";
import { activeFreeTrial } from "store/Slices/authSlice";

const OnBording = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { onboardingData, onBoardStep, loading } = useSelector((state) => state.boarding);
  const [activeStep, setActiveStep] = useState(0);
  const [savedAnswer, setSavedAnswer] = useState(null);
  const [answer, setAnswer] = useState("");
  const [modal, setmodal] = useState(false);
  const handleChange = (event) => {
    setAnswer(event.target.value);
  };

  const slugs = [
    {
      id: 1,
      slug: "nexa-usage-goals",
    },
    {
      id: 2,
      slug: "users-and-goals",
    },
    {
      id: 3,
      slug: "biggest-challenges",
    },
    {
      id: 4,
      slug: "career-success_measurement",
    },
    {
      id: 5,
      slug: "additional-resources",
    },
  ];

  // const fileInput = useRef(null);
  const goBack = () => {
    if (activeStep > 0) {
      dispatch(setOnboardStep(onBoardStep - 1));
      setActiveStep((prev) => prev - 1);
    }
  };
  const handleNext = (step) => {
    let payload = {
      question_slug: slugs[step - 2].slug,
    };
    if (!answer) {
      return toast.error("Value Can't be empty");
    } else {
      payload.answer = answer;
    }

    const handleResponse = (response) => {
      if (response) {
        if (step > 5) {
          setAnswer("");
          navigate("/introduction");
        } else {
          setAnswer("");
          dispatch(setOnboardStep(step));
          setActiveStep(step);
        }
      }
    };

    if (savedAnswer) {
      dispatch(updateOnboarding(payload))
        .unwrap()
        .then((response) => {
          setAnswer("");
          setSavedAnswer(null);
          dispatch(setOnboardStep(step));
          setActiveStep(step);
          if (step > 5) {
            setAnswer("");
            navigate("/introduction");
          }
        });
    } else {
      dispatch(AddBoarding({ payload }))
        .unwrap()
        .then((response) => {
          if (activeStep == 5) {
            toast.success(response.detail);
          }
          handleResponse(response);
        });
    }
  };

  const handleSubmitLater = (step) => {
    toast.success("Saved Successfully");
    navigate("/introduction");
  };

  useEffect(() => {
    dispatch(getBoarding());
  }, [activeStep]);

  useEffect(() => {
    if (onBoardStep > 0) {
      setActiveStep(onBoardStep);
    }
  }, [onBoardStep]);

  useEffect(() => {
    const currentSlug = slugs[activeStep - 1]?.slug;
    if (onboardingData && onboardingData?.length > 0) {
      const currentAnswer = onboardingData?.find((item) => item.question_slug === currentSlug);
      setSavedAnswer(currentAnswer);
      setAnswer(currentAnswer?.answer);
    }
  }, [activeStep]);

  useEffect(() => {
    if (user.activeBundle == null) {
      dispatch(activeFreeTrial());
    }
  }, []);

  return (
    <>
      {loading && <Loader />}
      {activeStep === 0 && <Terms2 handleNext={() => setActiveStep(1)} />}
      {activeStep !== 0 && (
        <OnboardingLayout
          activeStep={activeStep}
          handleNext={handleNext}
          goBack={goBack}
          submitLater={handleSubmitLater}
          answer={answer}
          modal={modal}
        >
          {activeStep === 1 && (
            <Box sx={{ pb: "50px", py: 3 }}>
              <Typography variant="h2">
                What are your primary goals for using Nexa’s career services platform?
              </Typography>
              {/* <Typography variant="p" sx={{ fontSize: "16px" }}>
                <b>Purpose:</b> Understand the organization's objectives and expectations to tailor
                the platform’s features and support to meet their specific needs.
              </Typography> */}
              <Box
                sx={{
                  marginBottom: "25px",
                }}
              >
                <TextField
                  multiline
                  rows={10}
                  placeholder="Type your answer here..."
                  value={answer}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  sx={{
                    borderRadius: "8px",
                    marginTop: "20px",
                    background: "#fff",
                    resize: "none",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#d6dae1",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#d6dae1",
                      },
                      borderRadius: "8px",
                      padding: "0px",
                    },
                    "& .MuiInputBase-input": {
                      padding: "29px",
                    },
                    "&::placeholder": {
                      color: "rgba(105, 116, 131, 0.7)",
                    },
                  }}
                />
              </Box>
            </Box>
          )}

          {activeStep === 2 && (
            <Box sx={{ pb: "50px", py: 3 }}>
              <Typography variant="h2">
                How many students or employees will be using Nexa, and what are their main areas of
                focus (e.g., cloud computing, data analytics, software development)?
              </Typography>
              {/* <Typography variant="p" sx={{ fontSize: "16px" }}>
                <b>Purpose:</b> Gauge the scale of usage and identify the key areas of interest to
                ensure the platform is optimized for their specific audience and subject areas.
              </Typography> */}
              <Box
                sx={{
                  marginBottom: "25px",
                }}
              >
                <TextField
                  multiline
                  rows={10}
                  placeholder="Type your answer here..."
                  value={answer}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  sx={{
                    borderRadius: "8px",
                    marginTop: "20px",
                    background: "#fff",
                    resize: "none",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#d6dae1",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#d6dae1",
                      },
                      borderRadius: "8px",
                      padding: "0px",
                    },
                    "& .MuiInputBase-input": {
                      padding: "29px",
                    },
                    "&::placeholder": {
                      color: "rgba(105, 116, 131, 0.7)",
                    },
                  }}
                />
              </Box>
            </Box>
          )}

          {activeStep === 3 && (
            <Box sx={{ pb: "50px", py: 3 }}>
              <Typography variant="h2">
                What are the biggest challenges your students or employees face in their job search
                and career preparation?
              </Typography>
              {/* <Typography variant="p" sx={{ fontSize: "16px" }}>
                <b>Purpose:</b> Identify the pain points and challenges to focus on providing
                solutions that address these specific issues effectively.
              </Typography> */}
              <Box
                sx={{
                  marginBottom: "25px",
                }}
              >
                <TextField
                  multiline
                  rows={10}
                  placeholder="Type your answer here..."
                  value={answer}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  sx={{
                    borderRadius: "8px",
                    marginTop: "20px",
                    background: "#fff",
                    resize: "none",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#d6dae1",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#d6dae1",
                      },
                      borderRadius: "8px",
                      padding: "0px",
                    },
                    "& .MuiInputBase-input": {
                      padding: "29px",
                    },
                    "&::placeholder": {
                      color: "rgba(105, 116, 131, 0.7)",
                    },
                  }}
                />
              </Box>
            </Box>
          )}
          {activeStep === 4 && (
            <Box sx={{ pb: "50px", py: 3 }}>
              <Typography variant="h2">
                How do you currently measure the success of your career services and job placement
                programs?
              </Typography>
              {/* <Typography variant="p" sx={{ fontSize: "16px" }}>
                <b>Purpose:</b> Understand their current metrics and success indicators to align
                Nexa’s features and reporting tools with their evaluation criteria.
              </Typography> */}
              <Box
                sx={{
                  marginBottom: "25px",
                }}
              >
                <TextField
                  multiline
                  rows={10}
                  placeholder="Type your answer here..."
                  value={answer}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  sx={{
                    borderRadius: "8px",
                    marginTop: "20px",
                    background: "#fff",
                    resize: "none",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#d6dae1",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#d6dae1",
                      },
                      borderRadius: "8px",
                      padding: "0px",
                    },
                    "& .MuiInputBase-input": {
                      padding: "29px",
                    },
                    "&::placeholder": {
                      color: "rgba(105, 116, 131, 0.7)",
                    },
                  }}
                />
              </Box>
            </Box>
          )}
          {activeStep === 5 && (
            <Box sx={{ pb: "50px", py: 3 }}>
              <Typography variant="h2">
                What additional resources or support would be most valuable to you in enhancing your
                career services program?
              </Typography>
              {/* <Typography variant="p" sx={{ fontSize: "16px" }}>
                <b>Purpose:</b> Determine what extra features, resources, or support services could
                add significant value, helping to prioritize future enhancements and customer
                service efforts.
              </Typography> */}
              <Box
                sx={{
                  marginBottom: "25px",
                }}
              >
                <TextField
                  multiline
                  rows={10}
                  placeholder="Type your answer here..."
                  value={answer}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  sx={{
                    borderRadius: "8px",
                    marginTop: "20px",
                    background: "#fff",
                    resize: "none",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#d6dae1",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#d6dae1",
                      },
                      borderRadius: "8px",
                      padding: "0px",
                    },
                    "& .MuiInputBase-input": {
                      padding: "29px",
                    },
                    "&::placeholder": {
                      color: "rgba(105, 116, 131, 0.7)",
                    },
                  }}
                />
              </Box>
            </Box>
          )}
        </OnboardingLayout>
      )}
    </>
  );
};

export default OnBording;
