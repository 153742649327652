import { Button, Pagination } from "@mui/material";
import Loader from "components/Loader";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getOctagonFeedback } from "store/Slices/usersSlice";
import TruncateText from "./TruncateText";
import moment from "moment";
import OctagonFeedbackModal from "examples/Modal/OctagonFeedback";

const UserOctagon = ({ storePermissions }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const spiltID = id?.split("_")?.[0];
  const { octagonFeedbackData, loading } = useSelector((state) => state.users);
  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [ocatgonModal, setOctagonModal] = useState(false);
  const [respt, setRespt] = useState("");
  const [conversation, setConversation] = useState("");
  const itemsPerPage = 10;
  const handleDisabled = (val) => {
    if (storePermissions) {
      if (storePermissions.hasOwnProperty(val)) {
        return !storePermissions[val];
      }
      return null;
    }
  };
  useEffect(() => {
    dispatch(getOctagonFeedback(spiltID));
  }, [spiltID]);
  useEffect(() => {
    setColumns([
      { Header: "Date of Simulation", accessor: "created_on", align: "left" },
      { Header: "Category", accessor: "role_category", align: "center" },
      { Header: "Feedback", accessor: "action", align: "center" },
    ]);
    // add user array
    const customAddUser =
      octagonFeedbackData &&
      octagonFeedbackData.length >= 1 &&
      octagonFeedbackData.map((data, index) => {
        return {
          created_on: (
            <MDTypography
              component="a"
              href="#"
              variant="button"
              color="text"
              fontWeight="medium"
              key={index + 100}
            >
              {moment(data?.created_on).format("DD/MM/YYYY HH:mm:ss A")}
            </MDTypography>
          ),
          role_category: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              <TruncateText text={data?.role_category_name ?? "Ocatgon"} />
            </MDTypography>
          ),

          action: (
            <MDBox sx={{ display: "flex", justifyContent: "start", alignItems: "start" }}>
              <Button
                variant="contained"
                size="small"
                disabled={handleDisabled("update") || loading}
                sx={{
                  ml: 1.3,
                  color: "#fff",
                  bgcolor: "info",
                  "&:hover": {
                    bgcolor: "darkslategray",
                  },
                }}
                onClick={() => handleOcatgonModal(data)}
              >
                Get Feedback
              </Button>
            </MDBox>
          ),
        };
      });
    setRows(Array.isArray(customAddUser) ? customAddUser : []);
  }, [octagonFeedbackData]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleOcatgonModal = (data) => {
    console.log("feedback", data);
    window.scrollTo(0, 0);
    setFeedbackLoading(true);
    setRespt(data?.feedback);
    setConversation(data?.conversations);
    setTimeout(() => {
      setFeedbackLoading(false);
      setOctagonModal(true);
    }, 2000);
  };
  if (loading || feedbackLoading) {
    return <Loader />;
  }
  return (
    <>
      <MDBox pt={3}>
        {octagonFeedbackData && octagonFeedbackData.length >= 1 ? (
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
            checkBoxes={false}
          />
        ) : (
          <MDTypography
            component="h2"
            href="#"
            color="text"
            fontWeight="bold"
            sx={{
              textAlign: "center",
              my: 10,
            }}
          >
            This user has no Simulations
          </MDTypography>
        )}
        <MDBox
          m={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Pagination
            count={Math.ceil((octagonFeedbackData?.length || 0) / itemsPerPage)}
            page={page}
            onChange={handlePageChange}
          />
        </MDBox>
      </MDBox>
      <OctagonFeedbackModal
        modal={ocatgonModal}
        handleClose={() => setOctagonModal(false)}
        feedback={respt}
        conversation={conversation}
      />
    </>
  );
};

export default UserOctagon;
