import { Card } from "@mui/material";
import Modal from "@mui/material/Modal";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
const OctagonFeedbackModal = ({ modal, handleClose, feedback, conversation }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    maxHeight: "80vh", // Limits the height to 80% of the viewport height
    boxShadow: 24,
    p: 4,
    overflowY: "auto", // Enables scrolling when content overflows
  };

  return (
    <div>
      <Modal
        open={modal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style}>
          <MDBox mx={1} p={1} mt={1} mb={1}>
            <div className="col-12 order-md-1 order-2">
              <div className="cardBody">
                <h3 className="mx-3 my-3 font-bold">Generalized Report:</h3>
                <hr />
                <h5 className="mx-3 evaluation">Evaluation Criteria and Scores:</h5>
                <div
                  className="summary mx-3 my-3"
                  dangerouslySetInnerHTML={{ __html: feedback }}
                ></div>
              </div>
              <div className="cardBody">
                <h3 className="mx-3 my-3 font-bold">Conversation:</h3>
                <hr />
                <div
                  className="summary mx-3 my-3"
                  dangerouslySetInnerHTML={{ __html: conversation }}
                ></div>
              </div>
            </div>
            <MDBox mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
              <MDButton variant="gradient" color="info" onClick={handleClose}>
                Close
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </Modal>
    </div>
  );
};

export default OctagonFeedbackModal;
