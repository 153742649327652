import { Clear, Search } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import MDInput from "components/MDInput";
import { useMemo, useState } from "react";
import { ConversationItem } from "./ConservationItem";
import { ShimmerCategoryList } from "react-shimmer-effects";

export const ConservationList = ({
  conservationLoading,
  conservations,
  activeChatId,
  setMessagesLoading,
}) => {
  const [isShowSearch, setIsShowSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const toggleSearch = () => setIsShowSearch(!isShowSearch);

  const handleSearchChange = (e) => setSearchQuery(e.target.value.toLowerCase());

  const filteredConversations = useMemo(() => {
    if (!searchQuery) return conservations;

    return conservations.filter(({ members }) =>
      members[0].name.toLowerCase().includes(searchQuery)
    );
  }, [searchQuery, conservations]);

  return (
    <Box
      sx={{
        height: "75vh",
        minWidth: 300,
        border: "1px solid #ccc",
        p: 3,
        mr: 2,
        backgroundColor: "#000",
      }}
      className="border rounded-lg"
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", pb: 2 }}>
        <Typography variant="h5" sx={{ color: "#fff", fontWeight: 600 }}>
          All Messages
        </Typography>
        <IconButton onClick={toggleSearch}>
          {isShowSearch ? <Clear sx={{ color: "#fff" }} /> : <Search sx={{ color: "#fff" }} />}
        </IconButton>
      </Box>

      {isShowSearch && (
        <MDInput
          sx={{ width: "100%", backgroundColor: "#fff", borderRadius: 2 }}
          placeholder="Search by name"
          value={searchQuery}
          onChange={handleSearchChange}
        />
      )}

      <Box className="conversation-container" sx={{ my: 2, height: "100%" }}>
        {conservationLoading ? (
          <ShimmerCategoryList title items={2} categoryStyle="STYLE_SEVEN" />
        ) : filteredConversations && filteredConversations?.length === 0 ? (
          <Typography
            variant="subtitle1"
            sx={{ color: "#fff", textAlign: "center", mt: 2, fontSize: 15 }}
          >
            No conversations found
          </Typography>
        ) : (
          filteredConversations?.map((conversation) => (
            <ConversationItem
              key={conversation.id}
              conversation={conversation}
              activeChatId={activeChatId}
              setMessagesLoading={setMessagesLoading}
            />
          ))
        )}
      </Box>
    </Box>
  );
};

export default ConservationList;
