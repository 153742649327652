import { useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import logo from "assets/images/logos/header-logo-official.png";

import BasicLayout from "layouts/authentication/components/BasicLayout";

import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { signupSchema } from "components/schema";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { createOrganization } from "store/Slices/authSlice";
import Loader from "components/Loader";
import { toast } from "react-toastify";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
const initialValues = {
  name: "",
  email: "",
  password: "",
  comfirmPassword: "",
  address: "",
  phone: "",
};

function Basic() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);
  const [passwordFieldType, setPasswordFieldType] = useState("password");
  const [conPasswordFieldType, setConPasswordFieldType] = useState("password");

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: initialValues,
    validationSchema: signupSchema,
    onSubmit: (values) => {
      const payload = values;
      dispatch(
        createOrganization({
          payload,
          onSuccess: () => {},
        })
      )
        .unwrap()
        .then((resolved) => {
          resetForm();
        })
        .catch((rejected) => {
          // resetForm();
          // console.log(rejected, "rejeccted");
          if (rejected.response.data.key === "Invalid request") {
            const messages = rejected.response.data.messages;

            for (const [key, value] of Object.entries(messages)) {
              // console.log({ key, value });
              value.forEach((msg) => {
                toast.error(`${key}: ${msg}`);
              });
            }
          }
          // toast.error(rejected?.response?.data?.key);
        });
    },
  });

  return (
    <BasicLayout image={bgImage}>
      {loading && <Loader />}
      <Card>
        {/* <MDBox
          variant="gradient"
          bgColor="white"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-2}
          p={2}
          mb={1}
          textAlign="center"
        >
          <img src={logo} alt="Logo" height={50} width={230} />
        </MDBox> */}
        <MDBox pt={2} px={3}>
          <MDBox
            // variant="gradient"
            // bgColor="white"
            // borderRadius="lg"
            // coloredShadow="info"
            mx={2}
            mt={1}
            p={2}
            mb={1}
            textAlign="center"
          >
            <img src={logo} alt="Logo" />
          </MDBox>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Organisation Name"
                name="name"
                fullWidth
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.name && touched.name ? (
                <MDTypography color="error" sx={{ fontSize: "13px" }}>
                  {<p>{errors.name}</p>}
                </MDTypography>
              ) : null}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Organization Email"
                name="email"
                fullWidth
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.email && touched.email ? (
                <MDTypography color="error" sx={{ fontSize: "13px" }}>
                  {<p>{errors.email}</p>}
                </MDTypography>
              ) : null}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Address"
                name="address"
                fullWidth
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.address && touched.address ? (
                <MDTypography color="error" sx={{ fontSize: "13px" }}>
                  {<p>{errors.address}</p>}
                </MDTypography>
              ) : null}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Phone"
                name="phone"
                fullWidth
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.phone && touched.phone ? (
                <MDTypography color="error" sx={{ fontSize: "13px" }}>
                  {<p>{errors.phone}</p>}
                </MDTypography>
              ) : null}
            </MDBox>
            <Box mb={2} sx={{ position: "relative" }}>
              <TextField
                type={passwordFieldType}
                label="Password"
                name="password"
                fullWidth
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setPasswordFieldType(passwordFieldType === "text" ? "password" : "text")
                        }
                        edge="end"
                      >
                        {passwordFieldType == "text" ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.password && touched.password ? (
                <MDTypography color="error" sx={{ fontSize: "13px" }}>
                  {<p>{errors.password}</p>}
                </MDTypography>
              ) : null}
            </Box>
            <Box mb={2} sx={{ position: "relative" }}>
              <TextField
                type={conPasswordFieldType}
                label="Confirm Password"
                name="comfirmPassword"
                fullWidth
                value={values.comfirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setConPasswordFieldType(
                            conPasswordFieldType === "text" ? "password" : "text"
                          )
                        }
                        edge="end"
                      >
                        {conPasswordFieldType == "text" ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.comfirmPassword && touched.comfirmPassword ? (
                <MDTypography color="error" sx={{ fontSize: "13px" }}>
                  {<p>{errors.comfirmPassword}</p>}
                </MDTypography>
              ) : null}
            </Box>
            <MDBox mt={2} mb={2}>
              <MDButton variant="gradient" color="info" type="submit" fullWidth>
                sign Up
              </MDButton>
            </MDBox>
            <MDBox mt={2} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Login
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
