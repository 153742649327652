import React, { useEffect, useState, useRef } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Loader";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Card,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Button,
} from "@mui/material";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useParams } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import Select from "@mui/material/Select";
import MDBox from "components/MDBox";
import { toast } from "react-toastify";
import { addNewTask } from "store/Slices/TaskSlice";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getJoinedUsers } from "store/Slices/usersSlice";
import { getSingleTask } from "store/Slices/TaskSlice";
import { udpateTask } from "store/Slices/TaskSlice";
import Multiselect from "multiselect-react-dropdown";
import TextAreaComponent from "components/TextAreaComponent";
import "dayjs/locale/en-gb";

dayjs.locale("en-gb");
const CreateTask = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref = useRef();
  const id = params.id;
  const { joinedUsers } = useSelector((state) => state.users);
  const { loading, getSingleTaskData } = useSelector((state) => state.task);
  const [show, setshow] = useState(false);
  const [attachment, setAttachment] = useState(getSingleTaskData?.task_details?.task_file || null);
  const [assignObject, setAssignObject] = useState([]);
  const [assignIds, setAssignIds] = useState([]);
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    duedate: "",
    priority: "",
    assignto: [],
    link: "",
  });
  console.log(formData, "formData");

  const handleSelectAll = () => {
    if (assignObject.length === joinedUsers.length) {
      // Deselect all
      setAssignObject([]);
      setFormData((prevState) => ({
        ...prevState,
        assignto: [],
      }));
    } else {
      // Select all
      setAssignObject(joinedUsers);
      setFormData((prevState) => ({
        ...prevState,
        assignto: joinedUsers.map((user) => user.customer_id),
      }));
    }
  };

  const handleMultiselectChange = (selectedList, selectedItem) => {
    setAssignObject(selectedList);
    setFormData((prevState) => ({
      ...prevState,
      assignto: selectedList.map((user) => user.customer_id),
    }));
  };

  const handleRemove = (selectedList, removedItem) => {
    setAssignObject(selectedList);
    setFormData((prevState) => ({
      ...prevState,
      assignto: selectedList.map((user) => user.customer_id),
    }));
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const [file] = files;
    setAttachment(file);
  };

  const handleDateChange = (newValue) => {
    setFormData((prevState) => ({
      ...prevState,
      duedate: newValue ? dayjs(newValue).format("DD/MM/YYYY") : "",
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "title" && value.length >= 200) {
      return setError(true);
    } else {
      setError(false);
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    formData.task_file = attachment;

    if (!formData.assignto.length) {
      toast.error("Please assign the task to someone.");
      return;
    }

    if (!formData.duedate) {
      toast.error("Please select the due date.");
      return;
    }
    if (dayjs(formData?.duedate, "DD/MM/YYYY").isBefore(dayjs(), "day")) {
      toast.error("Please select the due date greater than current date.");
      return;
    }

    if (attachment instanceof File && attachment.type !== "application/pdf") {
      toast.error("Please upload a PDF file.");
      return;
    }
    if (formData.task_file && formData.task_file.size > 1000000) {
      toast.error("File size exceeds 1MB. Please upload a smaller file.");
      return;
    }
    if (id) {
      formData.id = id;
      dispatch(
        udpateTask({
          formData,
          onSuccess: () => {
            toast.success("Task updated successfully");
            navigate("/Tasks");
          },
        })
      );
      console.log(formData, "formDataformDataonEdit");
    } else {
      dispatch(
        addNewTask({
          formData,
          onSuccess: () => {
            toast.success("Task created successfully");
            navigate("/Tasks");
          },
        })
      );
    }
  };

  useEffect(() => {
    if (!id) {
      setFormData({
        title: "",
        description: "",
        duedate: "",
        priority: "",
        assignto: [],
        link: "",
      });
      setAssignObject([]);
      setAttachment(null);
      setshow(false);
    } else {
      dispatch(
        getSingleTask({
          payload: id,
          onSuccess: (res) => {
            const selectedIds = res?.assigned_user_list
              ?.filter((item) => item && item.customer_id)
              .map((item) => item.customer_id);
            const selectedObjs = joinedUsers?.filter((item) =>
              selectedIds?.includes(item?.customer_id)
            );
            setFormData({
              title: res?.task_details?.title,
              description: res?.task_details?.description,
              duedate: dayjs(res?.task_details?.due_date).format("DD/MM/YYYY"),
              priority: res?.task_details?.priority,
              assignto: selectedIds || [],
              link: res?.task_details?.link,
            });
            setshow(res?.task_details?.task_file);
            setAssignObject(selectedObjs);
          },
        })
      );
    }
  }, []);

  useEffect(() => {
    dispatch(getJoinedUsers());
  }, []);
  return (
    <DashboardLayout>
      {loading && <Loader />}
      <Card style={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <Box
          variant="gradient"
          bgcolor="info.main"
          borderRadius="12px"
          boxShadow={3}
          mx={2}
          p={2}
          mb={1}
          textAlign="center"
        >
          <Typography variant="h4" fontWeight="medium" color="white.main" mt={1} mb={1}>
            {id ? "View Task" : "Create New Task"}
          </Typography>
        </Box>
        <Box mx={1} p={1} mt={1}>
          <form onSubmit={handleSubmit}>
            <Box mb={2}>
              <MDInput
                type="text"
                label="Title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                fullWidth
                required
                variant="outlined"
                maxLength={200}
              />
              {error && (
                <Typography variant="p" sx={{ color: "red", fontSize: "12px" }}>
                  You have reached the maximum character limit of 200!
                </Typography>
              )}
            </Box>
            <Box mb={1}>
              <TextAreaComponent
                name="description"
                value={formData.description}
                onChange={handleChange}
                rows={3}
                placeholder="Write Description..."
                required={true}
              />
            </Box>
            {!id && (
              <MDBox mb={2}>
                <Box mb={2}>
                  <Multiselect
                    isObject={true}
                    options={joinedUsers}
                    selectedValues={assignObject}
                    onSelect={handleMultiselectChange}
                    onRemove={handleRemove}
                    displayValue="customer_name"
                    placeholder="Assign To:"
                  />
                  <MDButton
                    variant="contained"
                    color="info"
                    onClick={handleSelectAll}
                    sx={{ marginTop: "10px" }}
                  >
                    {assignObject.length === joinedUsers.length ? "Deselect All" : "Select All"}
                  </MDButton>
                </Box>
              </MDBox>
            )}
            <Box mb={2} sx={{ display: "flex", flexDirection: "column" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Due Date"
                  value={formData.duedate ? dayjs(formData.duedate, "DD/MM/YYYY") : null}
                  onChange={handleDateChange}
                  format="DD/MM/YYYY"
                  minDate={dayjs()}
                  renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
                />
              </LocalizationProvider>
            </Box>
            <MDBox mb={2}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Priority:</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Priority"
                  name="priority"
                  value={formData.priority}
                  onChange={handleChange}
                  required
                >
                  <MenuItem value="LOW">Low</MenuItem>
                  <MenuItem value="MEDIUM">Medium</MenuItem>
                  <MenuItem value="HIGH">High</MenuItem>
                </Select>
              </FormControl>
            </MDBox>
            <Box mb={2}>
              <MDInput
                type="text"
                label="Link"
                name="link"
                value={formData.link}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              />
            </Box>

            <Box mb={2}>
              {show ? (
                <div className="profile-pic">
                  <img
                    src="https://www.iconpacks.net/icons/2/free-file-icon-1453-thumb.png"
                    height={100}
                    width={100}
                  />
                  <div className="edit" onClick={() => setshow(false)}>
                    <CancelIcon sx={{ color: "red" }} />
                  </div>
                </div>
              ) : (
                <div className="filebox">
                  <label className="filelabel">Attach File:</label>
                  <MDButton>
                    <TextField
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                      ref={ref}
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={handleFileChange}
                    />
                  </MDButton>
                </div>
              )}
            </Box>

            <Box mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
              <MDButton variant="gradient" color="info" onClick={() => navigate("/Tasks")}>
                Cancel
              </MDButton>
              <MDButton variant="gradient" color="info" type="submit" disabled={loading}>
                {id ? "Save" : "Submit"}
              </MDButton>
            </Box>
          </form>
        </Box>
      </Card>
    </DashboardLayout>
  );
};

export default CreateTask;
