import { Card } from "@mui/material";
import Modal from "@mui/material/Modal";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";

const UserLimitModal = ({ stopmodal, handleStopModal }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "90%", sm: "60%", md: "60%", lg: "40%" },
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Modal
        open={stopmodal}
        onClose={handleStopModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style}>
          <MDBox mx={1} p={1} mt={1}>
            <MDTypography variant="h5" mt={1} sx={{ textAlign: "center", fontWeight: "medium" }}>
              Free plan have limit of upto 5 users, please remove additional members to downgrade
            </MDTypography>
            <MDBox mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "center" }}>
              <Link to="/users">
                <MDButton variant="gradient" color="info">
                  Go To Users
                </MDButton>
              </Link>
            </MDBox>
          </MDBox>
        </Card>
      </Modal>
    </div>
  );
};

export default UserLimitModal;
