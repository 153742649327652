import { Box, Button, Card, Modal } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Payments from "layouts/payments";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getSubscription } from "store/Slices/authSlice";
import { reActivatePackage } from "store/Slices/authSlice";
import { checkOutSession } from "store/Slices/subscriptionSlice";

const ChangePaymentModal = ({ modal, onClose, setopenModal, role, activePricingId }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);
  const { declineError } = useSelector((state) => state.subScription);

  const getCurrentSubscription = () => {
    dispatch(getSubscription())
      .unwrap()
      .then((res) => {})
      .catch((rejected) => {
        toast.error(rejected?.response?.data?.detail);
      });
  };

  const handleReactivate = () => {
    if (role === "packageExpire") {
      dispatch(
        reActivatePackage({
          payload: declineError?.invoice_id,
          onSuccess: () => {
            onClose();
            dispatch(getSubscription());
          },
        })
      )
        .unwrap()
        .then((res) => {
          console.log(res, "resbyahsanuqbak");
        });
    } else {
      const payload = {
        bundlePlanId: activePricingId,
      };
      dispatch(checkOutSession(payload))
        .unwrap()
        .then((res) => {
          toast.success(res.message);
          if (!res.checkout_url) {
            getCurrentSubscription();
          } else {
            setTimeout(() => {
              window.location.href = res.checkout_url;
            }, 1500);
          }
        })
        .catch((res) => {
          toast.error(res?.response?.data?.detail);
        });
    }
    onClose();
    setopenModal(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "90%", sm: "80%", md: "80%", lg: "60%" },
    boxShadow: 24,
    p: 0,
    maxHeight: "95vh",
    minHeight: "80vh",
    overflowX: "hidden",
  };
  return (
    <Modal
      open={modal}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <MDBox mx={1} p={1} my={1}>
          <Payments />
          <Box display="flex" justifyContent="end" my={2} gap={2}>
            <MDButton variant="gradient" color="info" onClick={onClose}>
              Close
            </MDButton>
            <MDButton variant="gradient" color="info" disabled={loading} onClick={handleReactivate}>
              ReActivate Subscription
            </MDButton>
          </Box>
        </MDBox>
      </Card>
    </Modal>
  );
};

export default ChangePaymentModal;
