import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";
const themeRepo = RepositoryFactory.get("Theme");

export const updateOrgTheme = createAsyncThunk(
  "theme/update",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await themeRepo.updateTheme(payload);
      if (data.status_code === 200) {
        return data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getOrgTheme = createAsyncThunk("theme/getTheme", async (_, { rejectWithValue }) => {
  try {
    const { data } = await themeRepo.getTheme();
    return data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});
export const updateThemeLocal = createAsyncThunk(
  "theme/localTheme",
  async (color, { rejectWithValue }) => {
    try {
      return color;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateColor = createAsyncThunk(
  "theme/localColor",
  async (color, { rejectWithValue }) => {
    try {
      return color;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

/// Add quote funcion
export const createQuote = createAsyncThunk(
  "theme/createQuote",
  async ({ quote, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await themeRepo.createQuote(quote);
      if (data) {
        onSuccess();
      }
      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

/// get quote funcion
export const getQuote = createAsyncThunk(
  "theme/getQuote",
  async ({ onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await themeRepo.getQuote();
      if (data) {
        onSuccess(data?.data);
      }
      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const initialState = {
  orgTheme: {
    sidebar_type: "#000000",
    sidebar_color: "info",
  },
  loading: false,
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(updateThemeLocal.pending, (state) => {
        // console.log("Running");
      })
      .addCase(updateThemeLocal.fulfilled, (state, action) => {
        state.loading = false;
        state.orgTheme.sidebar_type = action.payload;
      })
      .addCase(updateThemeLocal.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(updateColor.pending, (state) => {
        // console.log("Running");
      })
      .addCase(updateColor.fulfilled, (state, action) => {
        state.loading = false;
        state.orgTheme.sidebar_color = action.payload;
      })
      .addCase(updateColor.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateOrgTheme.pending, (state) => {
        // console.log("Running");
        state.loading = true;
      })
      .addCase(updateOrgTheme.fulfilled, (state, action) => {
        state.loading = false;
        const { detail, ...rest } = action.payload;
        toast.success(detail);
        state.orgTheme = rest;
      })
      .addCase(updateOrgTheme.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getOrgTheme.pending, (state) => {
        // console.log("Running");
      })
      .addCase(getOrgTheme.fulfilled, (state, action) => {
        state.loading = false;
        const { detail, ...rest } = action.payload;
        // toast.success(detail);
        state.orgTheme = rest;
      })
      .addCase(getOrgTheme.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default themeSlice.reducer;
