import { Card, Grid } from "@mui/material";
import Modal from "@mui/material/Modal";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useDispatch, useSelector } from "react-redux";
import { getSubscription } from "store/Slices/authSlice";
import { reActivatePackage } from "store/Slices/authSlice";
import ChangePaymentModal from "./ChangePaymentModal";
import { useEffect, useState } from "react";
import { checkOutSession } from "store/Slices/subscriptionSlice";
import { toast } from "react-toastify";
import { getPaymentCard } from "store/Slices/profileSlice";
import MDBadge from "components/MDBadge";
import { cardBrands } from "layouts/payments/data/CardsData";

const UpdateSubscritionModal = ({
  openModal,
  setopenModal,
  activePricingId,
  setbuttonStatusLoader,
}) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);
  const { declineError } = useSelector((state) => state.subScription);
  const { payments } = useSelector((state) => state.orgProfile);
  const [modal, setModal] = useState(false);
  const [modifiedPayment, setModifiedPayment] = useState(null);
  const onClose = () => {
    setModal(!modal);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "90%", sm: "60%", md: "60%", lg: "50%" },
    boxShadow: 24,
    p: 4,
  };

  const getCurrentSubscription = () => {
    setbuttonStatusLoader(true);
    dispatch(getSubscription())
      .unwrap()
      .then((res) => {
        setbuttonStatusLoader(false);
      })
      .catch((rejected) => {
        toast.error(rejected?.response?.data?.detail);
        setbuttonStatusLoader(false);
      });
  };

  const handleReactivate = () => {
    const payload = {
      bundlePlanId: activePricingId,
    };
    dispatch(checkOutSession(payload))
      .unwrap()
      .then((res) => {
        toast.success(res.message);
        if (!res.checkout_url) {
          getCurrentSubscription();
        } else {
          setTimeout(() => {
            window.location.href = res.checkout_url;
          }, 1500);
        }
      })
      .catch((res) => {
        toast.error(res?.response?.data?.detail);
      });
    setopenModal(false);
  };

  useEffect(() => {
    const data = payments?.filter((item) => item?.isDefault);
    setModifiedPayment(data);
  }, [payments]);

  return (
    <div>
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style}>
          <MDBox mx={1} p={1} mt={1}>
            <MDTypography variant="h3" mt={1} sx={{ textAlign: "center", fontWeight: "medium" }}>
              Are you sure you want to update your subscription?
            </MDTypography>
            <Grid item xs={12} md={12} mt={2}>
              <MDBox
                borderRadius="lg"
                display="flex"
                alignItems="center"
                p={3}
                sx={{
                  flexDirection: { xs: "column", sm: "row" },
                  justifyContent: { xs: "center", sm: "space-between" },
                  border: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                }}
              >
                <MDBox
                  component="img"
                  // src={`/images/logos/${item?.brand}.png`}
                  src={
                    cardBrands.find((brand) => brand?.brand === modifiedPayment?.[0]?.brand)?.img
                  }
                  alt={modifiedPayment?.[0]?.brand}
                  width="10%"
                  // mr={2}
                  sx={{
                    marginRight: { xs: "0", sm: "20px" },
                    marginBottom: { xs: "20px", sm: "0" },
                  }}
                />
                <MDTypography
                  variant="h6"
                  fontWeight="medium"
                  sx={{
                    marginBottom: { xs: "20px", sm: "0" },
                  }}
                >
                  ****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;{modifiedPayment?.[0]?.last4}
                </MDTypography>
                <MDBox
                  // ml="auto"
                  lineHeight={0}
                  // color={darkMode ? "white" : "dark"}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: { xs: "0", sm: "auto" },
                  }}
                >
                  <MDBadge
                    badgeContent="Active"
                    color="success"
                    variant="gradient"
                    size="md"
                    sx={{
                      marginRight: "10px",
                    }}
                  />
                </MDBox>
              </MDBox>
            </Grid>
            <MDBox mt={4} mb={1} sx={{ display: "flex", gap: "15px", justifyContent: "end" }}>
              <MDButton variant="gradient" color="info" onClick={() => setopenModal(false)}>
                Close
              </MDButton>
              <MDButton
                variant="gradient"
                color="info"
                disabled={loading}
                onClick={handleReactivate}
              >
                Continue with same payment method
              </MDButton>
              <MDButton variant="gradient" color="info" disabled={loading} onClick={onClose}>
                Update Payment Method
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </Modal>
      <ChangePaymentModal
        modal={modal}
        onClose={onClose}
        setopenModal={setopenModal}
        role="subscription"
        activePricingId={activePricingId}
      />
    </div>
  );
};

export default UpdateSubscritionModal;
