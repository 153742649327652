import * as Yup from "yup";

export const addUser = Yup.object({
  email: Yup.string().email().required("Please Enter you Email"),
  phone: Yup.string()
    .matches(/^[+]?[0-9]*$/, "Only numbers and '+' sign are allowed")
    .nullable(),
});
export const addAdmin = Yup.object({
  email: Yup.string().email().required("Please Enter Admin Email"),
  role: Yup.string().required("Role is required"),
});
export const addSubOrg = Yup.object({
  email: Yup.string().email().required("Please Enter Email"),
  sub_organization_name: Yup.string().required("Sub Organization Name is required"),
});
export const addSubOrgUser = Yup.object({
  email: Yup.string().email().required("Please Enter Email"),
  sub_organization_user_name: Yup.string().required("Sub Organization User Name is required"),
});
export const profileSchema = Yup.object({
  name: Yup.string().required("Full Name is required"),
  phone: Yup.number().required("Phone Number is required"),
  address: Yup.string().required("Address is required"),
});
export const updatePassSchema = Yup.object({
  curPassword: Yup.string()
    .required("Current password is required")
    .min(8, "Current password must be at least 8 characters"),
  newPassword: Yup.string()
    .required("New password is required")
    .min(8, "New password must be at least 8 characters"),
});
export const SubscriptionSchema = Yup.object({
  users: Yup.number()
    .typeError("The number of users must be a number")
    .required("Please enter a valid number of users"),
});

export const PaymentSchema = Yup.object({
  cardNumber: Yup.string()
    .matches(/^\d{1,25}$/, "Card number must be up to 25 digits")
    .required("Please Enter your Card Number"),
  accountHolder: Yup.string().required("Please Enter you Holder Name"),
  account: Yup.string().required("Please choose your account"),
});

export const signupSchema = Yup.object().shape({
  name: Yup.string().required("Full Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  address: Yup.string().required("Address is required"),
  phone: Yup.string().required("Phone number is required"),
  password: Yup.string().required("Password is required"),
  comfirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});
export const registerSubAdminSchema = Yup.object().shape({
  fullname: Yup.string().required("Full Name is required"),
  password: Yup.string().required("Password is required"),
  cPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});
export const registerSubOrgSchema = Yup.object().shape({
  subOrgName: Yup.string().required("Organization Name is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .required("Password is required"),
  address: Yup.string().required("Address is required"),
  phone: Yup.string()
    .matches(/^[0-9]+$/, "Phone must be a number")
    .required("Phone is required"),
});
export const signInSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});
