import { Box, Button, Grid, Icon, Typography } from "@mui/material";
import Loader from "components/Loader";
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleReport } from "store/Slices/ReportAnalyticsSlice";
import ReportsHeader from "./ReportsHeader";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { toast } from "react-toastify";
import { permission } from "context";
import { getRouteByNumber } from "context";
import { featurePermissionsObject } from "context";
import ReportDetailsModal from "./ReportDetailModal";

const ViewReports = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { reports, singleFetchedReport, loading } = useSelector((state) => state.ReportAnalytics);
  const { user } = useSelector((state) => state.auth);
  const [singleReport, setSingleReport] = useState(null);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [storePermissions, setStorePermissions] = useState(null);

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);

  // Handle opening modal with selected report data
  const handleOpenModal = (reportData) => {
    setSelectedReport(reportData);
    setOpenModal(true);
  };

  // Handle closing modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedReport(null);
  };

  let pagePermissions;
  let featurePermissions;
  const parsedObject = JSON.parse(user?.rolePermissions);
  if (parsedObject) {
    const [key, value] = Object.entries(parsedObject)[0];
    pagePermissions = key;
    featurePermissions = value;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const handleDisabled = (val) => {
    if (storePermissions) {
      if (storePermissions.hasOwnProperty(val)) {
        return !storePermissions[val];
      }
      return null;
    }
  };

  useEffect(() => {
    if (id) {
      const spiltID = id?.split("_")?.[0];
      dispatch(getSingleReport(spiltID));
    }
  }, [id]);
  useEffect(() => {
    if (singleFetchedReport) {
      setSingleReport(singleFetchedReport);
    }
  }, [singleFetchedReport]);
  console.log(singleReport);

  useEffect(() => {
    if (singleReport?.type === "top_resumes") {
      setColumns([
        { Header: "Customer Name", accessor: "customer_name", align: "left" },
        { Header: "Job Name", accessor: "job_role_name", align: "left" },
        { Header: "Job Title", accessor: "job_role_title", align: "left" },
        { Header: "Created At", accessor: "created_on", align: "center" },
        { Header: "score", accessor: "score", align: "center" },
        { Header: "Feedback Status", accessor: "feedback_status", align: "center" },
        { Header: "Action", accessor: "action", align: "center" },
      ]);
    } else if (singleReport?.type === "top_jobs_applied") {
      setColumns([
        { Header: "Customer Name", accessor: "customer_name", align: "left" },
        { Header: "Applied Count", accessor: "applied_jobs_count", align: "center" },
      ]);
    } else if (singleReport?.type === "top_jobs_searched") {
      setColumns([
        { Header: "Customer Name", accessor: "customer_name", align: "left" },
        { Header: "Job Title", accessor: "job_title", align: "left" },
        { Header: "Created At", accessor: "created_on", align: "left" },
        { Header: "Searched Count", accessor: "search_count", align: "center" },
      ]);
    } else if (singleReport?.type !== "resume") {
      setColumns([
        { Header: "Customer Name", accessor: "customer_name", align: "left" },
        { Header: "Category Name", accessor: "category_name", align: "center" },
        { Header: "Created At", accessor: "created_on", align: "center" },
        { Header: "score", accessor: "score", align: "center" },
        { Header: "Feedback Status", accessor: "feedback_status", align: "center" },
      ]);
    } else {
      setColumns([
        { Header: "Customer Name", accessor: "customer_name", align: "left" },
        { Header: "Company Name", accessor: "company_name", align: "left" },
        { Header: "Job Role", accessor: "job_role", align: "left" },
        { Header: "Created At", accessor: "created_on", align: "center" },
        { Header: "score", accessor: "score", align: "left" },
        { Header: "Feedback Status", accessor: "feedback_status", align: "center" },
      ]);
    }

    // add user array
    const reportsArray =
      singleReport &&
      singleReport?.report?.length >= 1 &&
      singleReport?.report
        ?.map((data, index) => {
          if (singleReport?.type === "top_resumes") {
            return {
              customer_name: (
                <MDTypography
                  component="a"
                  href="#"
                  variant="button"
                  color="text"
                  fontWeight="medium"
                  key={index + 100}
                >
                  {data?.customer_name}
                </MDTypography>
              ),
              job_role_name: (
                <MDTypography
                  component="a"
                  href="#"
                  variant="button"
                  color="text"
                  fontWeight="medium"
                  key={index + 100}
                >
                  {data?.job_role_name}
                </MDTypography>
              ),
              job_role_title: (
                <MDTypography
                  component="a"
                  href="#"
                  variant="button"
                  color="text"
                  fontWeight="medium"
                  key={index + 100}
                >
                  {data?.job_role_title}
                </MDTypography>
              ),
              created_on: (
                <MDTypography variant="caption" color="text" fontWeight="medium">
                  {formatDate(data?.created_on)}
                </MDTypography>
              ),
              score: (
                <MDTypography variant="caption" color="text" fontWeight="medium">
                  {data?.score}
                </MDTypography>
              ),
              feedback_status: (
                <MDTypography variant="caption" color="text" fontWeight="medium">
                  {data?.feedback_status ? "Feedback Taken" : "Feedback Pending"}
                </MDTypography>
              ),
              action: (
                <MDBox sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Button
                    variant="contained"
                    size="small"
                    disabled={!data?.feedback_status}
                    onClick={() => handleOpenModal(data)}
                    sx={{
                      ml: 1.3,
                      color: "#fff",
                      bgcolor: "black",
                      "&:hover": {
                        bgcolor: "darkslategray",
                      },
                    }}
                  >
                    <Icon color="#fff" sx={{ fontSize: "20px" }}>
                      visibility
                    </Icon>
                  </Button>
                </MDBox>
              ),
            };
          } else if (singleReport?.type === "top_jobs_applied") {
            return {
              customer_name: (
                <MDTypography
                  component="a"
                  href="#"
                  variant="button"
                  color="text"
                  fontWeight="medium"
                  key={index + 100}
                >
                  {data.customer_name}
                </MDTypography>
              ),
              applied_jobs_count: (
                <MDTypography variant="caption" color="text" fontWeight="medium">
                  {data.applied_jobs_count}
                </MDTypography>
              ),
            };
          } else if (singleReport?.type === "top_jobs_searched") {
            return {
              customer_name: (
                <MDTypography
                  component="a"
                  href="#"
                  variant="button"
                  color="text"
                  fontWeight="medium"
                  key={index + 100}
                >
                  {data.customer_name}
                </MDTypography>
              ),
              job_title: (
                <MDTypography
                  component="a"
                  href="#"
                  variant="button"
                  color="text"
                  fontWeight="medium"
                  key={index + 100}
                >
                  {data?.job_title}
                </MDTypography>
              ),
              created_on: (
                <MDTypography variant="caption" color="text" fontWeight="medium">
                  {formatDate(data?.created_on)}
                </MDTypography>
              ),
              search_count: (
                <MDTypography variant="caption" color="text" fontWeight="medium">
                  {data.search_count}
                </MDTypography>
              ),
            };
          } else if (singleReport?.type !== "resume") {
            return {
              customer_name: (
                <MDTypography
                  component="a"
                  href="#"
                  variant="button"
                  color="text"
                  fontWeight="medium"
                  key={index + 100}
                >
                  {data?.customer_name}
                </MDTypography>
              ),
              category_name: (
                <Typography
                  component="a"
                  href="#"
                  variant="caption"
                  color="textPrimary"
                  fontWeight="medium"
                >
                  <Box
                    component="span"
                    sx={{
                      ...pillSx,
                      backgroundColor: data?.status === "completed" ? "green" : "#2882EA",
                      color: "#fff",
                      maxWidth: "300px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                      display: "inline-block",
                      verticalAlign: "middle",
                    }}
                    title={data?.category_name ? data?.category_name : data?.job_description}
                  >
                    {data?.category_name ? data?.category_name : data?.job_description}
                  </Box>
                </Typography>
              ),
              created_on: (
                <MDTypography variant="caption" color="text" fontWeight="medium">
                  {formatDate(data?.created_on)}
                </MDTypography>
              ),
              score: (
                <MDTypography variant="caption" color="text" fontWeight="medium">
                  {data?.score}
                </MDTypography>
              ),
              feedback_status: (
                <MDTypography variant="caption" color="text" fontWeight="medium">
                  {data?.feedback_status === 2 ? "Feedback Taken" : "Feedback Pending"}
                </MDTypography>
              ),
              // action: (
              //   <MDBox sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              //     <Button
              //       variant="contained"
              //       size="small"
              //       sx={{
              //         ml: 1.3,
              //         color: "#fff",
              //         bgcolor: "black",
              //         "&:hover": {
              //           bgcolor: "darkslategray",
              //         },
              //       }}
              //     >
              //       <Icon color="#fff" sx={{ fontSize: "20px" }}>
              //         delete
              //       </Icon>
              //     </Button>
              //   </MDBox>
              // ),
            };
          } else {
            return {
              customer_name: (
                <MDTypography
                  component="a"
                  href="#"
                  variant="button"
                  color="text"
                  fontWeight="medium"
                  key={index + 100}
                >
                  {data?.customer_name}
                </MDTypography>
              ),
              company_name: (
                <MDTypography
                  component="a"
                  href="#"
                  variant="button"
                  color="text"
                  fontWeight="medium"
                  key={index + 100}
                >
                  {data?.company_name}
                </MDTypography>
              ),
              job_role: (
                <MDTypography
                  component="a"
                  href="#"
                  variant="button"
                  color="text"
                  fontWeight="medium"
                  key={index + 100}
                >
                  {data?.job_role}
                </MDTypography>
              ),
              created_on: (
                <MDTypography variant="caption" color="text" fontWeight="medium">
                  {formatDate(data?.created_on)}
                </MDTypography>
              ),
              score: (
                <MDTypography variant="caption" color="text" fontWeight="medium">
                  {data?.score}
                </MDTypography>
              ),
              feedback_status: (
                <MDTypography variant="caption" color="text" fontWeight="medium">
                  {data?.feedback_status ? "Feedback Taken" : "Feedback Pending"}
                </MDTypography>
              ),
            };
          }
        })
        .filter(Boolean);

    setRows(Array.isArray(reportsArray) ? reportsArray : []);
  }, [singleReport]);

  useEffect(() => {
    if (pagePermissions !== permission.admin) {
      const routeLink = getRouteByNumber(pagePermissions);
      return navigate(routeLink);
    }
    if (featurePermissions) {
      const data = featurePermissionsObject(featurePermissions);
      setStorePermissions(data);
      console.log(data, "featurePermissionsfeaturePermissions");
    }
  }, []);

  const pillSx = {
    display: "inline-block",
    padding: "0.2em 0.6em",
    borderRadius: "9999px",
    fontSize: "0.75rem",
    fontWeight: 500,
    textAlign: "center",
    color: "white",
  };
  const handleDownloadReport = () => {
    setDownloadLoading(true);
    if (!Array.isArray(singleFetchedReport.report) || singleFetchedReport.report.length === 0) {
      toast.error("No reports to download");
      setDownloadLoading(false);
      return;
    }
    const doc = new jsPDF();

    const columns = ["Customer Name", "Category Name", "Created At", "Score", "Feedback Status"];
    const data = singleFetchedReport?.report?.map((report) => [
      report.customer_name,
      report.category_name ? report.category_name : report.job_description,
      formatDate(report?.created_on), // Format the date
      report.score,
      report.feedback_status === 2 ? "Feedback Taken" : "Feedback Pending",
    ]);

    const topResumeColumns = [
      "Customer Name",
      "Job Role",
      "Job Title",
      "Created At",
      "Score",
      "Feedback Status",
    ];
    const topResumeData = singleFetchedReport?.report?.map((report) => [
      report.customer_name,
      report.job_role_name,
      report.job_role_title,
      formatDate(report?.created_on), // Format the date
      report.score,
      report.feedback_status === 2 ? "Feedback Taken" : "Feedback Pending",
    ]);

    const appliedColumns = ["Customer Name", "Applied Count"];
    const appliedData = singleFetchedReport?.report?.map((report) => [
      report.customer_name,
      report.applied_jobs_count,
    ]);

    const searchColumns = ["Customer Name", "Job Title", "Created At", "Search Count"];
    const searchData = singleFetchedReport?.report?.map((report) => [
      report.customer_name,
      report.job_title,
      formatDate(report?.created_on), // Format the date
      report.search_count,
    ]);

    const resumeColumns = [
      "Customer Name",
      "Company Name",
      "Job Role",
      "Created At",
      "Score",
      "Feedback Status",
    ];
    const resumeData = singleFetchedReport?.report?.map((report) => [
      report.customer_name,
      report.company_name,
      report.job_role,
      formatDate(report?.created_on), // Format the date
      report.score,
      report.feedback_status === 2 ? "Feedback Taken" : "Feedback Pending",
    ]);

    // Add a title
    doc.text(`Nexa ${singleFetchedReport?.type} Report`, 85, 20);
    doc.autoTable({
      head: [
        singleFetchedReport?.type == "resume"
          ? resumeColumns
          : singleFetchedReport?.type == "top_jobs_applied"
          ? appliedColumns
          : singleFetchedReport?.type == "top_jobs_searched"
          ? searchColumns
          : singleFetchedReport?.type == "top_resumes"
          ? topResumeColumns
          : columns,
      ],
      body:
        singleFetchedReport?.type == "resume"
          ? resumeData
          : singleFetchedReport?.type == "top_jobs_applied"
          ? appliedData
          : singleFetchedReport?.type == "top_jobs_searched"
          ? searchData
          : singleFetchedReport?.type == "top_resumes"
          ? topResumeData
          : data,
      startY: 30,
    });

    // Save the PDF
    doc.save(`Nexa_${singleFetchedReport?.type}_Report.pdf`);
    setDownloadLoading(false);
  };

  return (
    <>
      <DashboardLayout>
        {(loading || downloadLoading) && <Loader />}
        <ReportsHeader
          title="View Report"
          handleDownloadReport={handleDownloadReport}
          handleDisabled={handleDisabled}
        >
          <Grid item xs={12} mt={5}>
            {singleFetchedReport?.report && singleFetchedReport?.report?.length >= 1 ? (
              <DataTable
                table={{ columns, rows }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
                checkBoxes={false}
              />
            ) : (
              <MDTypography
                component="h2"
                href="#"
                color="text"
                fontWeight="bold"
                sx={{
                  textAlign: "center",
                  my: 10,
                }}
              >
                No Reports Found
              </MDTypography>
            )}
          </Grid>
        </ReportsHeader>
        {/* Modal for viewing report details */}
        {selectedReport && (
          <ReportDetailsModal
            open={openModal}
            handleClose={handleCloseModal}
            reportData={selectedReport}
          />
        )}
      </DashboardLayout>
    </>
  );
};

export default ViewReports;
